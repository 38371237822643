@import '~styles/mixins.sass';

.sprint-container {
	@include display-flex-center-mixin;
	flex-direction: column;
	margin-right: 1.2vw;
	height: 5.208vh;
	box-sizing: border-box; }

.sprint-content {
	@include display-flex-center-mixin;
	flex-direction: row;
	align-items: center;
	width: 14.2vw;
	border-radius: 4px;
	padding: 7px 0px;
	height: 100%;
	box-sizing: border-box; }

.sprint-name-desc-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sprint-name-day-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	width: 3.6vw;
	height: 100%;
	padding-top: 2px; }

.sprint-name {
	padding-right: 1px;
	font-size: 10px; }

.sprint-day-progress-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%; }

.sprint-day-container {
	font-size: 16px;
	margin-top: -5px; }

.sprint-progress-container {
	padding-left: 5px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.sprint-day {
	width: 0.439vw;
	height: 100%;
	margin-right: 0.2vw;
	border-radius: 5px; }

.sprint-current-day {
	width: 0.439vw;
	height: 100%;
	margin-right: 0.2vw;
	border-radius: 6px;
	@include display-flex-center-mixin; }
