.legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 0.5vh; }

.wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0.5vh 0vw 0.5vh 0vw;
    height: 19.922vh;
    width: 16.4vw;
    margin-top: 1vh;
    box-sizing: border-box;
    border-radius: 4px; }

.semi-donut-container {
    --percentage: 0;
    margin: auto;
    width: 14.3vw;
    height: 13.3vh;
    //border: 1px solid red
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box; }

.semi-donut {
    position: absolute;
    top: 0;
    left: 0;
    width: 14.3vw;
    height: 14.3vw;
    border: 3vw solid;
    box-sizing: border-box;
    border-radius: 50%;
    border-color: #ECECEC #ECECEC var(--fill) var(--fill);
    transform: rotate( calc( 1deg * ( -45 + var(--percentage) * 1.8 ) ) ); }

.line-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7.15vw;
    height: 1px;
    background: transparent;
    transform-origin: 0 50%;
    transform: rotate(-135deg);
    box-shadow: -1px -1px 0.2vw 0.05vw #00000026, 0vw -2px 0.2vw 0vw #00000026 inset; }

.semi-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: white;
    transform: translate(-50%, -50%);
    border-radius: 50%; }


.semi-donut-outer-shadow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 14.3vw;
    height: 14.3vw;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0vw 0.191vw 0.2vw 0vw #00000026 inset; }

.semi-donut-inner-shadow {
    position: absolute;
    top: 3vw;
    left: 3vw;
    width: 8.3vw;
    height: 8.3vw;
    z-index: 9;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0vw -0.191vw 0.2vw 0vw #00000026; }

.semi-donut-left-shadow {
    position: absolute;
    bottom: 0;
    left: 1px;
    width: 2.9vw;
    height: 1px;
    z-index: 9;
    box-shadow: 0vw -0.1vw 0.2vw 0.03vw #00000026; }

.semi-donut-right-shadow {
    position: absolute;
    bottom: 0;
    right: 1px;
    width: 2.9vw;
    height: 1px;
    //border-radius: 50%
    z-index: 9;
    box-shadow: 0px -0.1vw 0.2vw 0.03vw #00000026; }

.graph {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; }

.square {
    width: 12px;
    height: 12px;
    margin-right: 4px; }

.leg-wrap {
    display: flex;
    align-items: center;
    margin-left: 0.5vw; }

.green {
    background: #B5F000; }

.white {
    background: #FFFFFF; }

.grey {
    background: #DADADA; }
