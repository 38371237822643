@import '~styles/mixins.sass';

.popover-container {
	// position: fixed
	width: 39.45vw;
	border-radius: 4px;
	z-index: 100;
	position: relative; }

.popover-container-story {
	width: 35.755vw;
	min-height: 18.078vh;
	border-radius: 4px;
	z-index: 100; }

.popover-container-event {
	width: 37.775vw;
	min-height: 18.229vh;
	border-radius: 4px;
	z-index: 100; }

.popover-container-child {
	position: absolute;
	top: 50.5%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 96%;
	height: 95%;
	z-index: -1; }

.popover-header {
	@include display-flex-space-between;
	width: 100%;
	box-sizing: border-box; }

.title-and-description {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: 1.3vh 1.4vw; }

.title {
	margin-bottom: 3px;
	margin-top: 1vh; }

.close-container {
	@include display-flex-end;
	width: 20%;
	margin-right: 20px; }

.close-svg {
	width: 26px;
	height: 26px;
	cursor: pointer; }

.choose-options {
	margin: 1.563vh 1.464vw  0 1.464vw; }

.options-container {
	margin: 0.5vh 1vw 0vh 1vw; }

.button-container {
	@include display-flex-center-mixin;
	margin: 1.5vh 0; }

.popover-arrow {
	position: absolute;
	width: 0;
	height: 0; }

.error-message {
	margin: 20px;
	padding: 1vh 0.5vw 1vh 0.5vw; }
