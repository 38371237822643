@import '~styles/mixins.sass';

.oa {
	overflow: auto; }

.unfinshed-task {
	width: 100%;
	height: 100%; }

.vertical-bar {
	height: 49.021vh;
	width: 1px;
	margin-top: 15px;
	box-sizing: border-box; }

.task-graph {
	width: 100%;
	margin-top: 1vh;
	overflow: hidden; }

.unfinished-container {
	display: flex;
	justify-content: space-between;
	box-sizing: border-box; }

.walkthrough-background {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0; }

.sprint-report-container {
	width: 100%;
	position: relative;
	box-sizing: border-box;
	height: 87.5vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: 'Open Sans'; }

.sprint-report-content-container {
	width: 100%;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	box-sizing: border-box;
	height: 90%; }

.sprint-report-popup-content-container {
	@include col-report-popup-container;
	padding: 0; }

.master {
	background-size: cover;
	//background-image: url("/static/media/sim_background.ac319c29.png")
	overflow: auto;
	height: 89vh; }

.sprint-report-component {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 0; }

.current-sprint-container {
	margin: auto;
	width: 91vw;
	display: flex;
	justify-content: space-between;
	margin-top: 1vh; }

.report-info-container {
	width: 96vw;
	height: 73.047vh;
	background: white;
	margin: auto;
	margin-top: 2vh;
	border-radius: 8px; }

.report-content {
	overflow-y: auto;
	overflow-x: hidden;
	height: 58.031vh;
	margin-top: 1vh; }

.report-content::-webkit-scrollbar-track {
	background: transparent; }

.tabs {
	height: 10.417vh;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 4.587vw 0 4.587vw; }

.tabs-popup {
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.7vh 3.8vw 0 4.587vw;
	box-sizing: border-box; }

.text {
	width: 91vw;
	font-family: 'Open Sans';
	margin: 1vh auto;
	padding: 1.7vh 2vw 1.7vh 2vw;
	box-sizing: border-box;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	div:nth-child(2) {
		margin-top: 0vh; } }

.info {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 20px 0;
	margin: 2vh 4.587vw; }

.info-left-container {
	display: flex;
	flex-direction: column;
	width: 55.782vw;
	box-sizing: border-box;
	padding-right: 1.3vw; }

.metrics {
	box-sizing: border-box;
	width: 100%; }

.met-name {
	display: flex;
	height: 4.427vh;
	padding: 0.521vh 16px 0.521vh 1vw;
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	align-items: center; }

.heading {
	width: 100%;
	display: flex;
	height: 4.427vh;
	padding: 4px 16px 4px 1vw;
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	align-items: center; }

.game-completion-wrappper {
	width: 91.801vw;
	margin: auto;
	padding: 0 0.51vw 0 0.51vw;
	border-radius: 8px;
	position: relative; }

.footer-svg1 {
	position: absolute;
	z-index: -1;
	bottom: 7.5vh;
	width: 88.3vw;
	height: 3vh; }

.footer-svg2 {
	position: absolute;
	z-index: -1;
	width: 56vw;
	height: 9vh;
	bottom: 1.5vh; }

.footer-svg3 {
	position: absolute;
	z-index: 0;
	bottom: -1vh;
	right: 0; }

.customers-speak {
	width: 90%; }

.task {
	width: 38.7vw;
	box-sizing: border-box;
	padding-left: 15px; }

.task-row {
	display: flex;
	flex-direction: column;
	width: 100%; }

.heading-text {
	padding-right: 10px; }

.game-completed {
	background: #FFFFFF;
	width: 88.3vw;
	height: 34.245vh;
	border-radius: 5px;
	display: flex;
	align-items: center;
	margin: auto;
	margin-bottom: 5vh; }

.img {
	width: 21.449vw;
	height: 28.516vh;
	margin-left: 2vw; }

.content {
	margin-left: 1vw;
	width: 62vw; }


.title {
	max-width: 100%; }

.ms {
	padding: 0.5vh 0px 4vh 0px;
	max-width: 100%; }

.download-button {
	background: linear-gradient(114.68deg, #FFFFFF 3.7%, #FFFFFF 100%);
	opacity: 0.9;
	border: 1px solid #232882;
	border-radius: 4px;
	width: 220px;
	height: 46px;
	color: #232882;
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	line-height: 46px;
	cursor: pointer; }

.walkthrough-tabs-container {
	padding: 3vh 0 1vh 0; }

.flow {
	position: relative; }

.buttons-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }

.single-button-container {
	margin-right: 1vw;
	width: 20vw;
 }	//height: 5.208vh

.help-text-report {
	height: 20px;
	padding-top: 4px;
	width: 300px; }
