body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: rgba(0, 0, 0, 0.7);
	font-size: "1.171vw";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 0.29vw;
	/* border-radius: 8px; */
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(152, 152, 152, 0.5);
	/* border-radius: 8px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(152, 152, 152, 1);
	/* border-radius: 8px; */
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.6);
} */

.flex-box-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.base-app-box {
	width: 100%;
	height: 100vh;
	min-width: 1270px;
	min-height: 600px;
	/* -webkit-transform:translate3d(0,0,0); */
	position: relative;
	overflow: hidden;
	-webkit-touch-callout: none;
	/* iOS Safari*/
	-webkit-user-select: none;
	-webkit-user-drag: none;
	/* Chrome/Safari/Opera*/
	-khtml-user-select: none;
	-khtml-user-drag: none;
	/* Konqueror*/
	-moz-user-select: none;
	-moz-user-drag: none;
	/* Firefox*/
	-ms-user-select: none;
	-ms-user-drag: none;
	/* Internet Explorer/Edge*/
	user-select: none;
}


/* React joyride tooltip */
.react-joyride__spotlight {
	/* --padding: 8px 8px; */
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	/* padding: var(--padding); */
	background-clip: content-box;
	box-shadow: inset 0 0 0 var(--padding) rgba(0, 0, 0, 0);
	box-sizing: border-box;
	background-color: #FD1E510C;
}

.react-joyride__tooltip {
	width: auto !important;
	max-width: 80vw !important;
	padding: 0 5px !important;
	box-shadow: 2px 2px 4px 0px #00000033 !important;
	/* background-image: url('https://knolskape-website.s3.amazonaws.com/misc/suyog_patil/2019/07/18/73/rectangle_4.4.svg') !important; */
	background-size: cover;

}

/* .react-joyride__tooltip div:nth-child(1) {text-align: left !important;	
}

.react-joyride__tooltip div:nth-child(1) div {
	padding: 10px !important;
	padding-bottom: 20px !important;
}*/

.react-joyride__tooltip div:nth-child(2) {
	margin-top: 0px !important;
	padding-bottom: 0.5vw
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-primary"] {
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__tooltip div:nth-child(2) button[data-test-id="button-back"] {
	/* display: none !important */
	padding: 7px 34px !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #232882 !important;
	margin-right: 8px !important;
	border-radius: 0px !important;
	background: linear-gradient(108.59deg, #FFFFFF 3.7%, #FFFFFF 100%) !important;
	opacity: 0.9 !important;
	border: 1px solid #232882 !important;
}

.react-joyride__overlay {
	cursor: default !important;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.loader_loader-container__2Opi8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.loader_svg-container__IxvHI {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loader_loadingMove__cTFiT 3s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.loader_svg-wrapper__3owkA {
  width: 100%;
  height: 100%; }

@keyframes loader_loadingMove__cTFiT {
  0%, 100% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(180deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.bundleLoader_container__3lYc5 {
  width: 100vw;
  height: calc(100vh - 52px);
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopHeader_header-container__1ldQv {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@media only screen and (min-width: 1280px) {
  .desktopHeader_header-content__2VMiv {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .desktopHeader_header-content__2VMiv {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .desktopHeader_header-content__2VMiv {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.desktopHeader_header-container__1ldQv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 8.3vh;
  min-width: 1280px;
  padding-right: 5.75vw;
  padding-left: 2.2vw;
  position: relative; }

.desktopHeader_header-content__2VMiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 100%; }

.desktopHeader_left-header__3SsOk {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0; }

.desktopHeader_middle-header__wQJZq {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  margin-left: 1.7vw; }

.desktopHeader_right-header__2Az84 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  grid-gap: 0.4vw;
  gap: 0.4vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simLogo_sim-logo-container__16ryq {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.simLogo_sim-logo__2bdVz {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.simLogo_sim-name__2JwEe {
  padding-left: 5px; }

.simLogo_sim-image__3XZbU {
  width: 17.7vw;
  height: 6.881vh;
  margin-left: 6.881vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simUserProfile_tooltip-container-bottom__RaS4G {
  width: auto;
  position: absolute;
  top: 0.4vh;
  right: 14.1vw;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: center; }

.simUserProfile_tooltip-container-bottom__RaS4G {
  height: 3.646vh;
  white-space: nowrap; }

.simUserProfile_tooltip-arrow-top-bottom__11qVl {
  width: 0;
  height: 0;
  margin-top: 8px; }

.simUserProfile_tooltip-arrow-right__3mu9C {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -0.7vw;
  width: 0.7vw; }

.simUserProfile_tooltip-content__gpDG5 {
  box-sizing: border-box;
  width: 100%;
  text-align: left; }

.simUserProfile_tooltip-content__gpDG5 {
  position: relative;
  padding: 0.7vh 0.5vw; }

.simUserProfile_info-icon__n9xG3 {
  width: 100%;
  height: 100%; }

.simUserProfile_user-profile-container-with-options__1d_b3 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none; }

.simUserProfile_user-profile-container__13Hb4 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.simUserProfile_user-profile-image-container__2pkrp {
  width: 2vw;
  height: 2vh; }

.simUserProfile_user-profile-arrow-container__1-vXo {
  margin-left: 0.4vw;
  width: 0.777vw;
  height: 2.446vh; }

.simUserProfile_user-profile-option-container__1FzLE {
  position: absolute;
  width: 13.836vw;
  height: 4.2vw;
  z-index: 50;
  top: 5.3vh;
  left: -11vw;
  padding-top: 0.3vh; }

.simUserProfile_each-option__1FPh_ {
  width: 100%;
  padding: 1vh 0.5vw;
  box-sizing: border-box; }

.simUserProfile_user-name-container__3TwRU {
  height: 4vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5vh 0.5vw; }

.simUserProfile_user-name-container__3TwRU {
  position: relative;
  overflow: visible; }

.simUserProfile_user-name__oXYru {
  height: 50%;
  width: 100%;
  text-align: left;
  padding: 5px 0;
  word-break: break-all; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userProfile_main-component__39Dyz {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.upArrow_main-component__3gOWm {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.downArrow_main-component__2YUjl {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.confirmLogout_modalOverlay__2TKg2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; }

.confirmLogout_modalContent__1ro77 {
  background: white;
  border-radius: 5px;
  text-align: center;
  position: relative;
  width: 49.634vw;
  height: 39.063vh; }

.confirmLogout_header__3lttM {
  background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
  border-radius: 5px 5px 0 0;
  color: white;
  padding-top: 1.5vh;
  height: 17.969vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.confirmLogout_body__1ILC2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.confirmLogout_iconContainer__3BcN- {
  display: flex;
  align-items: center;
  height: 7vh; }

h2 {
  font-size: 1em; }

p {
  width: 37vw;
  height: 5vh;
  text-align: center; }

.confirmLogout_modalActions__1WQq8 {
  padding-top: 2.2vh;
  display: flex;
  justify-content: center;
  grid-gap: 3vh;
  gap: 3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.filledButton_button-style__Y1HcJ {
  box-sizing: border-box; }

.filledButton_button-style__Y1HcJ {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10.474vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 1.042vh 1.171vw 1.042vh 1.171vw;
  height: 40px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.logoutArrow_main-component__3Ik6Y {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.infoComponent_info-container__z4dqV {
  width: 0.7vw;
  height: 1.5vh;
  position: relative;
  padding-right: 2px; }

.infoComponent_info-icon__2uF1p {
  width: 100%;
  height: 100%; }

.infoComponent_tooltip-container-right__34weQ {
  width: 250px;
  position: absolute;
  top: -5px;
  left: 20px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-left__3SQ27 {
  width: 250px;
  position: absolute;
  top: -5px;
  left: -250px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.infoComponent_tooltip-container-top__309Fd {
  width: 12vw;
  position: absolute;
  top: 14px;
  left: -5.6vw;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    align-items-font-size: 0.7em; }

@media only screen and (max-height: 760px) {
  .infoComponent_tooltip-container-top__309Fd {
    width: 12vw;
      width-align-text: right; } }

.infoComponent_tooltip-container-bottom__1X5Qb {
  width: 250px;
  position: absolute;
  top: 18px;
  left: -116px;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.infoComponent_tooltip-arrow-left-right__1Kd39 {
  width: 0;
  height: 0;
  margin-top: 8px; }

.infoComponent_tooltip-arrow-top-bottom__cXfUm {
  width: 0;
  height: 0; }

.infoComponent_tooltip-content__2DpTE {
  box-sizing: border-box;
  padding: 7px;
  width: calc(100% - 7px);
  text-align: left; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.infoIcon_main-component__1aEUC {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simProfileArrow_main-component__3oQoj {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.timer_timer-container__2tIix {
  height: 65%;
  width: 5.887vw;
  margin-right: 0.4vw;
  margin-left: 0.4vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }

.timer_timer-content__3vl5I {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px 5px 5px;
  box-sizing: border-box;
  text-align: center;
    text-align-overflow: hidden;
    text-align-white-space: nowrap; }

.timer_timer-content-ar__1qwxJ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simObjectives_objectives-container__LOVfr {
  height: 5.199vh;
  width: 8.39vw;
  padding: 8px 0.5vw 8px 0.5vw;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  justify-content: space-evenly;
  grid-gap: 3px;
  gap: 3px;
  margin-right: 0.5vw; }

.simObjectives_objectives-image-container__7Oc90 {
  width: 1.464vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simObjectives_objectives-image__BFtBT {
  width: 1.318vw;
  height: 2.344vh; }

.simObjectives_objectives-text-container__jmA3N {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.headerSeparation_separation__28FE4 {
  width: 2px;
  height: 5.3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.objectives_main-component__LX83- {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simLeaderBoard_leaderboard-container__Uzxz2 {
  height: 5.199vh;
  width: 35%;
  padding: 0.5vh 0.5vw;
  box-sizing: border-box;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  justify-content: space-evenly;
  margin-right: 0.6vw;
  margin-left: 0.5vw; }

.simLeaderBoard_leaderboard-image-container__NSZ0S {
  width: 1.318vw;
  height: 2.344vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.simLeaderBoard_leaderboard-image__1O3QS {
  width: 1.318vw;
  height: 2.344vh; }

.simLeaderBoard_leaderboard-text-container__1V8JI {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.leaderboard_main-component__QBZRj {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simMVP_sim-mvp-container__25jHG {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 5.199vh;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-right: 1vw;
  padding: 0 0.2vw;
  border-radius: 8px; }

.simMVP_mvp-down-arrow__jcPG9 {
  position: absolute;
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  top: 100%;
  left: 0;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: simMVP_showDownArrow__-y_ZR 0.5s 1; }

.simMVP_down-arrow__1_ilC {
  width: 10px;
  height: 5px; }

.simMVP_sim-mvp-content__37LnS {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0vw 4px 0px;
  height: 100%;
  box-sizing: border-box; }

.simMVP_sim-mvp-details__y8qHT {
  width: 9.883vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start; }

.simMVP_sim-mvp-top__1CMW9 {
  width: 10.321vw;
  height: 55%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box; }

.simMVP_sim-mvp-text__2zbwx {
  padding-right: 0.4vw; }

.simMVP_sim-mvp-value__a4cov {
  margin-right: 0.4vw; }

.simMVP_sim-mvp-bottom__3cBHT {
  width: 9.883vw;
  height: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2px;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow-container__1KQkk {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.simMVP_sim-mvp-arrow__9zJ_n {
  width: 5px;
  height: 5px; }

.simMVP_sim-mvp-progress-bar-container__3rALU {
  width: 100%;
  height: 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  overflow: hidden; }

.simMVP_sim-mvp-progress-bar__3yRBV {
  height: 7px;
  border-radius: 3px;
  display: flex; }

@keyframes simMVP_showDownArrow__-y_ZR {
  from {
    top: 80%;
    opacity: 0; }
  to {
    top: 100%;
    opacity: 1; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simProgress_sim-progress-container__c04cp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0px 12px 0vw;
  box-sizing: border-box;
  height: 5.199vh; }

.simProgress_sim-progress-child__pILM0 {
  width: 23.4vw; }

.simProgress_sim-progress-child__pILM0 {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.simProgress_sim-progress-content__o66z2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0.3vw 0px 0.8vw;
  margin-top: 5px;
  position: relative;
  height: 5.199vh; }

.simProgress_progress-start-text__2dkfc {
  padding-right: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.simProgress_progress-end-text__1-HpJ {
  padding-left: 5px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px; }

.simProgress_progress-container__TzjCh {
  display: flex; }

.simProgress_end-days-container__3lYjp {
  width: 2px;
  height: 10px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_middle-days-container__1iBRJ {
  width: 2px;
  height: 6px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_current-days-container__2sx1K {
  width: 2px;
  height: 8px;
  margin-right: 1px;
  border-radius: 2px;
  box-sizing: border-box; }

.simProgress_day-tag-container__2zHp9 {
  position: absolute;
  width: 45px;
  height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center; }

.simProgress_sprint-section-container__2icRq {
  width: 28%; }

.simProgress_sprint-container__2VmrE {
  display: flex;
  width: 4.3vw;
  height: 1.8vh;
  box-sizing: border-box;
  padding: 2px;
  justify-content: space-evenly;
  grid-gap: 3px;
  gap: 3px;
  margin-right: 1px; }

.simProgress_progress-value__3YfdP {
  display: flex;
  margin-right: 8px; }

.simProgress_deadline-bar-container__2upmJ {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  grid-gap: 5px;
  gap: 5px; }

.simProgress_deadline-container__1LSkr {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.desktopFooter_footer-container__q2GwO {
  width: 100%;
  height: 2.995vh;
  position: absolute;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center; }

.desktopFooter_sim-name__2LiZF {
  padding: 0 4px; }

.desktopFooter_sim-tag__b98Pw {
  padding: 0 4px; }

.desktopFooter_knolskape-logo__2TwmY {
  padding: 0 2px 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.desktopFooter_footer-bar__1cwFD {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.planningWalkthrough_container__3G-Ps {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.planningWalkthrough_wrapper__6llAV {
  width: 74vw; }

.planningWalkthrough_description__16IBn {
  text-align: left;
  width: 46vw;
  padding-right: 6px; }

.planningWalkthrough_description-steps-container__1r0nf {
  padding: 3.125vh 1.757vw;
  margin: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18vh;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.planningWalkthrough_steps-container__3MfZw {
  width: 17.58vw; }

.planningWalkthrough_button-container__1jI9e {
  display: flex;
  flex-direction: column;
  width: 24.451vw;
  margin: auto; }

.planningWalkthrough_button-next__2dxgU {
  display: flex;
  flex-direction: column;
  width: 11.713vw;
  margin: auto; }

.planningWalkthrough_walkthrough-background__3XDpv {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

.planningWalkthrough_seperation-line2__LAOfE {
  width: 2px;
  height: 110px;
  margin-right: 1vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughTabs_tabs-container__3t81I {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh; }

.walkthroughTabs_tab-line-container__UIi8y {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.walkthroughTabs_tab-seperation-line__1nlRZ {
  width: 6vw;
  height: 1px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughTab_tab-container__Zr4bM {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3vw;
  gap: 0.3vw;
  height: 4.948vh;
  width: 11vw;
  box-sizing: border-box;
  border-radius: 4px; }

.walkthroughTab_symbol-container__Wh2xd {
  display: flex;
  align-items: center;
  width: 1.171vw;
  height: 2.283vh; }

.walkthroughTab_tab-name__pI0S0 {
  margin-bottom: 2px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.circleTick_main-component__1_LEq {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.hollowCircle_main-component__2Ab7D {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.filledCircle_main-component__2rHY2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughSteps_container__3kf9w {
  box-sizing: border-box; }

.walkthroughSteps_seperation-line__1Zmu9 {
  width: 1px;
  height: 1.8vh;
  margin: 2px 1.6vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.walkthroughStep_container__zG0R_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 17.57vw; }

.walkthroughStep_number__3Ifo8 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.724vw;
  height: 1.724vw;
  padding-bottom: 0.4vh;
  box-sizing: border-box; }

.walkthroughStep_check-container__2boDd {
  width: 1.724vw;
  height: 1.724vw; }

.walkthroughStep_title__1dCTS {
  margin-left: 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskButtonHollow_main-component__2f3Nh {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.executionWalkthrough_container__2Zgfr {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.executionWalkthrough_wrapper__2nBby {
  width: 100%; }

.executionWalkthrough_description__z6F4J {
  margin: 25px 100px 20px 100px;
  text-align: center; }

.executionWalkthrough_sprints-container__3WyNk {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vh; }

.executionWalkthrough_button-container__fPS_1 {
  display: flex;
  flex-direction: column;
  width: 24.451vw;
  margin: auto; }

.executionWalkthrough_walkthrough-background__uKYZZ {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintsWalkthrough_container__2V-5B {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 2vw;
  gap: 2vw;
  margin: 8px 0px 25px 0px;
  overflow: hidden; }

.sprintsWalkthrough_seperation-line__1zWjJ {
  width: 1px;
  height: 12px;
  margin: 2px 14px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintWalkthrough_container__23gQv {
  width: 14.641vw;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding-bottom: 2vh; }

.sprintWalkthrough_disabled-container__11hPi {
  zoom: 0.92; }

.sprintWalkthrough_rotate-image__2nT1c {
  width: 135px;
  height: 135px;
  position: absolute;
  top: 11px;
  left: 15px;
  opacity: 0.5; }

.sprintWalkthrough_title__l62G7 {
  width: 85%;
  height: 4.427vh;
  padding: 0vh 0.586vw 0.621vh 0.586vw;
  box-sizing: border-box;
  margin: auto;
  margin-top: 2vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWalkthrough_steps-container__1-64n {
  margin: 1.7vh 0 0 2vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.overlay_complete-container__29Jft {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 50; }

.overlay_bottom-container__2Dohx {
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.overlay_bottom-container-component__25r2J {
  min-height: 100px;
  width: 100%; }

.overlay_right-container__3XYMO {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }

.overlay_center-container__WIjZH {
  display: flex;
  justify-content: center;
  align-items: center; }

.overlay_right-container-component__1RvWF {
  min-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.actionResponse_container__3gD7k {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponse_container__3gD7k {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 38.021vh;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0; }

.actionResponse_action-response-child__Mw7fQ {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 36.6vh; }

.actionResponse_image-container__2iCu2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 28.516vh;
  border-radius: 1px;
  box-sizing: border-box; }

.actionResponse_image__2dz-G {
  width: 21.318vw;
  height: 28.516vh; }

.actionResponse_response-container__1uL0g {
  width: 75%;
  height: 100%;
  box-sizing: border-box; }

.actionResponse_person-name__3r2gs {
  width: 80%;
  height: 100%;
  box-sizing: border-box; }

.actionResponse_response-message__1yORT {
  width: 100%;
  box-sizing: border-box; }

.actionResponse_close-container__2puqb {
  position: absolute;
  bottom: 3vh;
  right: 2.5vw; }

.actionResponse_close-container__2puqb .actionResponse_continue__3C8XK {
  width: 50px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponseMetrics_metrics-container__3PUcl {
  width: 100%;
  margin-top: 3vh; }

.actionResponseMetrics_top-metrics-container__gse3L {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_left-metric-container__2tASG {
  min-height: 67px;
  border-radius: 18px;
  margin: 0 1vh;
  box-sizing: border-box; }

.actionResponseMetrics_left-metrics-value-change-container__1-iKJ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px; }

.actionResponseMetrics_metrics-image__2dFcE {
  width: 1.171vw;
  height: 2.083vh;
  margin-right: 8px; }

.actionResponseMetrics_bottom-metrics-container__1y8K2 {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  margin-left: 15.4vw; }

.actionResponseMetrics_bottom-metrics-wrapper__2Mww_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  box-sizing: border-box; }

.actionResponseMetrics_right-metric-container__1Kc11 {
  padding: 0 1vh; }

.actionResponseMetrics_metric-title__DeGlU {
  text-transform: uppercase; }

.actionResponseMetrics_right-metrics-value-change-container__33Si0 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_metrics-value__2KZpm {
  margin-right: 0.878vw; }

.actionResponseMetrics_metrics-change__sg3VY {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.294vw;
  min-height: 2.604vh;
  padding-right: 0.5vw;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center; }

.actionResponseMetrics_arrow-container__1Sgja {
  width: 1.171vw;
  height: 2.083vh; }

.actionResponseMetrics_mvp-title-value-container__X2rHX {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_mvp-value__2FbIf {
  margin-left: 0.878vw; }

.actionResponseMetrics_sim-mvp-progress-bar-container__2PXNl {
  width: 100%;
  height: 6px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionResponseMetrics_sim-mvp-progress-bar__31IiY {
  height: 6px;
  border-radius: 2px;
  display: flex; }

.actionResponseMetrics_game-metric-container__1Racx {
  padding: 0 2.196vw;
  box-sizing: border-box; }

.actionResponseMetrics_game-metrics-container__3aTUi {
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionResponseMetrics_metric-container__3xX2A {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.208vh;
  margin-right: 1.464vw;
  padding-right: 0.3vw; }

.actionResponseMetrics_metric-content__3R2GY {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  grid-gap: 0.4vw;
  gap: 0.4vw;
  height: 6.1vh;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px 6px; }

.actionResponseMetrics_metric-name-desc-container__34ai0 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.actionResponseMetrics_metric-name-value-container__3p2gl {
  display: flex;
  flex-direction: column;
  align-itmes: start;
  justify-content: space-between;
  padding-top: 8px; }

.actionResponseMetrics_metric-name__1v_Qk {
  padding-right: 1px; }

.actionResponseMetrics_metric-image-container__bxfTl {
  display: flex;
  height: 100%;
  align-items: center; }

.actionResponseMetrics_metric-image__2YyKo {
  width: 1.757vw; }

.actionResponseMetrics_metric-value__39guI {
  padding-left: 0px;
  margin-top: -0.6vh; }

.actionResponseMetrics_metric-value-right__1uujz {
  margin-right: 3px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.incrementArrow_main-component__YRkZj {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.decrementArrow_main-component__3W4dZ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.flagIcon_main-component__2HTIl {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintMetrics_sprint-container__2nb2P {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1.2vw;
  height: 5.208vh;
  box-sizing: border-box; }

.sprintMetrics_sprint-content__2Q1tx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 14.2vw;
  border-radius: 4px;
  padding: 7px 0px;
  height: 100%;
  box-sizing: border-box; }

.sprintMetrics_sprint-name-desc-container__17pv5 {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintMetrics_sprint-name-day-container__mxOWS {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 3.6vw;
  height: 100%;
  padding-top: 2px; }

.sprintMetrics_sprint-name__1pmFc {
  padding-right: 1px;
  font-size: 10px; }

.sprintMetrics_sprint-day-progress-container__QlPi3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%; }

.sprintMetrics_sprint-day-container__3Hjgw {
  font-size: 16px;
  margin-top: -5px; }

.sprintMetrics_sprint-progress-container__KFoQE {
  padding-left: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintMetrics_sprint-day__1Rf7E {
  width: 0.439vw;
  height: 100%;
  margin-right: 0.2vw;
  border-radius: 5px; }

.sprintMetrics_sprint-current-day__2J4GC {
  width: 0.439vw;
  height: 100%;
  margin-right: 0.2vw;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.rightArrow_mainComponent__dr4Md {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.budget_main-component__2h8cj {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.skill_main-component__1WXAN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.morale_main-component__1Byvt {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.custSat_main-component__vysiW {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.event_event-actions-container__GuhcM {
  width: 88.76vw;
  height: 40.234vh;
  border-radius: 8px;
  margin-top: 50vh;
  position: relative; }

.event_event-child__6zgG0 {
  width: 87.2vw;
  height: 37.5vh;
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%); }

.event_event-actions-header__2Q-Lm {
  width: 100%;
  height: 6.25vh;
  padding-left: 1vw;
  display: flex;
  align-items: center;
  box-sizing: border-box; }

.event_event-text__2AQ_c {
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-gap: 3vh;
  gap: 3vh;
  width: 44.656vw; }

.event_event-actions-content__awnb4 {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 2.2vh 3vw 1vh 3vw;
  box-sizing: border-box;
  position: relative; }

.event_event-actions-content__awnb4 {
  height: 33.985vh;
  grid-gap: 2vw;
  gap: 2vw; }

.event_event-image-container__3uBvt {
  width: 20.328vw; }

.event_image__1Revr {
  width: 100%; }

.event_event-action-list__-01NF {
  height: 100%; }

.event_metrics-container__1g_nO {
  display: flex; }

.event_metric-container__nMGeD {
  height: 5.208vh;
  margin-right: 1.464vw;
  padding-right: 0.3vw;
  display: flex; }

.event_metric-content__2q_vA {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.4vw;
  gap: 0.4vw;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px 6px; }

.event_metric-image__2Y0o4 {
  width: 1.757vw; }

.event_metric-image-container__2m3Ub {
  display: flex;
  height: 100%;
  align-items: center; }

.event_metric-name-value-container__1BYXq {
  display: flex;
  flex-direction: column;
  align-itmes: start;
  justify-content: space-between;
  padding-top: 8px; }

.event_metric-name-desc-container__1PvSl {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.event_metric-name__3tZsr {
  padding-right: 1px; }

.event_metric-value__JyELv {
  padding-left: 0px;
  margin-top: -0.6vh; }

.event_metrics-change__2tGQx {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.294vw;
  min-height: 2.604vh;
  padding-right: 0.5vw;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center; }

.event_arrow-container__31qLs {
  width: 1.171vw;
  height: 2.083vh; }

.event_close-container__1Cy9Z {
  position: absolute;
  bottom: 4vh;
  right: 2.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionList_actions-list-container__1fMrR {
  width: 16.5vw;
  padding-top: 1vh; }

.actionList_each-action-container__3j7ua {
  margin-bottom: 1.302vh; }

.actionList_each-action-container__3j7ua:last-child {
  overflow-y: hidden; }

.actionList_overlay__2l5h9 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.action_action-wrapper__3PLiz {
  position: relative;
  top: 0;
  left: 0; }

.action_action-container__1BMEJ {
  width: 100%;
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 0.2vw;
  border-radius: 4px; }

.action_action-container-story__cqRIk {
  width: 12.738vw;
  min-height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 2.73px; }

.action_action-image-container__20qUW {
  width: 3.125vh;
  height: 3.125vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.action_action-image-story__2he-b {
  width: 2.083vh;
  height: 2.083vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.action_action-image__2C7Rl {
  width: 20px;
  height: 20px; }

.action_action-name__5WXwg {
  width: calc(100% - 36px);
  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.action_disable-action__2c3t1 {
  pointer-events: none;
  opacity: 0.5; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionOptionsPopover_popover-container__1_M_H {
  width: 39.45vw;
  border-radius: 4px;
  z-index: 100;
  position: relative; }

.actionOptionsPopover_popover-container-story__1pEI9 {
  width: 35.755vw;
  min-height: 18.078vh;
  border-radius: 4px;
  z-index: 100; }

.actionOptionsPopover_popover-container-event__2CcW4 {
  width: 37.775vw;
  min-height: 18.229vh;
  border-radius: 4px;
  z-index: 100; }

.actionOptionsPopover_popover-container-child__wHlDH {
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  height: 95%;
  z-index: -1; }

.actionOptionsPopover_popover-header__3pE8y {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box; }

.actionOptionsPopover_title-and-description__1lSIj {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 1.3vh 1.4vw; }

.actionOptionsPopover_title__1KUlz {
  margin-bottom: 3px;
  margin-top: 1vh; }

.actionOptionsPopover_close-container__VtHZl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  margin-right: 20px; }

.actionOptionsPopover_close-svg__98wmt {
  width: 26px;
  height: 26px;
  cursor: pointer; }

.actionOptionsPopover_choose-options__1aRWR {
  margin: 1.563vh 1.464vw  0 1.464vw; }

.actionOptionsPopover_options-container__3nVc3 {
  margin: 0.5vh 1vw 0vh 1vw; }

.actionOptionsPopover_button-container__Gq9jV {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5vh 0; }

.actionOptionsPopover_popover-arrow__2rz3y {
  position: absolute;
  width: 0;
  height: 0; }

.actionOptionsPopover_error-message__35rOH {
  margin: 20px;
  padding: 1vh 0.5vw 1vh 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.close_main-component__2zSzx {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionOption_option-container__2X_24 {
  display: flex;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.5vh 0.5vw;
  margin-bottom: 1vh;
  cursor: pointer; }

.actionOption_radio-container__3jEzd {
  width: 0.878vw;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.9vh;
  padding-right: 0.5vw; }

.actionOption_option-details-container__1sokY {
  width: 100%; }

.actionOption_radio-outline__3FK2L {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }

.actionOption_radio-inner-circle__2nMuZ {
  width: 0.439vw;
  height: 0.439vw;
  border-radius: 50%;
  margin: 0.18vw;
  box-sizing: border-box; }

.actionOption_option-effort-cost-container__Fc2ef {
  display: flex;
  justify-content: space-between; }

.actionOption_option-effort-cost-content__39daX {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.actionOption_option-effort-cost-title__EpgA6 {
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.teamLunch_main-component__ioISu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.standup_main-component__3frcQ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.marketPulseSurvey_main-component__2a-T4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.rebuildFeature_main-component__2OjPX {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprint_main-component__3afUc {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.retrospect_main-component__1o91a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.teamMotivationSurvey_main-component__3CNsO {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.updatePrd_main-component__FSwaS {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userFeedback_main-component__12mGo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.userResearch_main-component__zGcmU {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.training_main-component__3v66i {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.meetTheTeam_main-component__1aWS0 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mom_main-component__1m7h7 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.talkOnAgile_main-component__1mOTK {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.newActions_main-component__n0hTC {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.endSprintConfirmation_main-component__2D45u {
  width: 34.407vw;
  height: 24.219vh;
  border-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container__1J7Ij {
  width: 100%;
  height: 12.76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.endSprintConfirmation_popup-header-container-content__2A6xu {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.endSprintConfirmation_popup-header-container-background__M1TjR {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.endSprintConfirmation_popup-details-container__B5bWr {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_popup-buttons-container__3LqGo {
  width: 100%;
  margin-top: 3vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.endSprintConfirmation_button-container__YZHd0 {
  padding: 0 10px;
  width: 11.713vw;
  height: 5.208vh; }

.endSprintConfirmation_header-image__jHbSp {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.endSprintConfirmation_header-text__TYanP {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.endSprintConfirmation_header-desc__3hSQo {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.hollowButton_button-style__2gSn5 {
  box-sizing: border-box; }

.hollowButton_button-style__2gSn5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-top: 2px;
  padding: 8px 16px 8px 16px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.triangleBackground_main-component__2vPQ1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.autofillSprintConfirmation_main-component__hQahV {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container__3XiQb {
  width: 100%;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.autofillSprintConfirmation_popup-header-container-content__3h3Fg {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.autofillSprintConfirmation_popup-header-container-background__1h0UA {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.autofillSprintConfirmation_popup-details-container__GwcpO {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_popup-buttons-container__2T98d {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.autofillSprintConfirmation_button-container__2ahSb {
  padding: 0 10px; }

.autofillSprintConfirmation_header-image__3d-Wy {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.autofillSprintConfirmation_header-text__3cqxP {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.autofillSprintConfirmation_header-desc__1mJak {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprintConfirmation_main-component__1R7XO {
  width: 35.933vw;
  height: 28.44vh;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container__3xWzh {
  width: 100%;
  height: 14.985vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintConfirmation_popup-header-container-content__Z3ehf {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintConfirmation_popup-header-container-background__1aTQO {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintConfirmation_popup-details-container__65EjR {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_popup-buttons-container__QNduF {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintConfirmation_button-container__CVodt {
  padding: 0 10px; }

.replanSprintConfirmation_header-image__lzL0r {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintConfirmation_header-text__2TNS7 {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintConfirmation_header-desc__2mXeP {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.replanSprintHelpPopup_main-component__pDMCk {
  width: 541px;
  height: 249px;
  border-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container__3veT9 {
  width: 100%;
  height: 12.76vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.replanSprintHelpPopup_popup-header-container-content__3yhDC {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px; }

.replanSprintHelpPopup_popup-header-container-background__3QeMJ {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.replanSprintHelpPopup_popup-details-container__3qGHM {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_popup-buttons-container__1pCxV {
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.replanSprintHelpPopup_button-container__CN2ks {
  padding: 0 10px; }

.replanSprintHelpPopup_header-image__1AhKl {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.replanSprintHelpPopup_header-text__1FUkp {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0; }

.replanSprintHelpPopup_header-desc__81J42 {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskConfirmation_main-component__1vUaN {
  width: 49.634vw;
  height: 38.073vh;
  border-radius: 8px;
  overflow: hidden; }

.addTaskConfirmation_popup-header-container__3WunL {
  width: 100%;
  min-height: 18.234vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  padding: 25px;
  box-sizing: border-box; }

.addTaskConfirmation_popup-header-container-content__3xaxI {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 50px; }

.addTaskConfirmation_popup-header-container-background__2h7Ad {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.addTaskConfirmation_popup-details-container__1Ciq4 {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_popup-buttons-container__3Wx0E {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 3vw;
  gap: 3vw;
  padding: 1vh 0 1vh 0;
  box-sizing: border-box; }

.addTaskConfirmation_button-container__2cpUC {
  padding: 0 10px;
  width: 10.474vw;
  height: 40px; }

.addTaskConfirmation_header-image__C9ZlF {
  width: 48px;
  height: 48px;
  margin-bottom: 16px; }

.addTaskConfirmation_header-text__15ac9 {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 10px 0;
  word-break: break-all; }

.addTaskConfirmation_header-desc__3JsEj {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.addTaskConfirmation_details-container__KdVwa {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2vh 0 1vh 0; }

.addTaskConfirmation_tasks-container__3T4H6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.addTaskConfirmation_storyPoints-container__1DcE1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.storyPopup_story-popup-container__fz82B {
  position: relative; }

.storyPopup_story-popup-child__g-ZMx {
  position: absolute;
  top: 50.25%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 94.5%;
  width: 97.5%; }

.storyPopup_header-container__1Ww3U {
  width: 51.835vw;
  min-height: 14vh;
  position: relative; }

.storyPopup_header-without-story__p4pO4 {
  min-height: 9vh;
  width: 59.327vw; }

.storyPopup_content-container__1Q4Hl {
  width: 51.835vw;
  min-height: 20.052vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 20px; }

.storyPopup_content-container-blocker__24zaH {
  min-height: 42.188vh;
  width: 59.327vw; }

.storyPopup_header-background-container__PUx5w {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.storyPopup_header-content-container__2LQK7 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px; }

.storyPopup_header-details-container__3NrU9 {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.1vw; }

.storyPopup_story-name__2_PQq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_story-details__1oQcT {
  width: 100%;
  height: 20%;
  display: flex;
  margin-top: 1vh;
  justify-content: flex-start;
  align-items: center; }

.storyPopup_close-container__3rovR {
  width: 5.857vw;
    width-height: 0.763vh;
  margin-bottom: 4vh;
  margin-right: 20px;
  border-radius: 2px; }

.storyPopup_close-container-blocker__3mS3h {
  width: 5.857vw;
    width-height: 0.763vh;
  margin-bottom: 1.8vh;
  margin-right: 20px;
  border-radius: 2px; }

.storyPopup_close-icon__dE-_A {
  width: 5.857vw;
    width-height: 0.763vh; }

.storyPopup_each-detail-container__3Pr2T {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.464vw; }

.storyPopup_each-detail-name__370bV {
  padding-right: 5px; }

.storyPopup_each-detail-value__2HDtU {
  padding-right: 10px; }

.storyPopup_each-detail-info__1f3KE {
  width: 10px;
  height: 10px; }

.storyPopup_story-description-container__1qVTI {
  width: 75%;
  height: 35%;
  box-sizing: border-box;
  padding: 1.302vh 1.1vw; }

.storyPopup_story-history-container__nJz8X {
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  padding: 10px 1.1vw; }

.storyPopup_story-description-container-full__8Za7S {
  width: 100%;
  height: 35%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-history-container-full__3WNWw {
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  padding: 24px; }

.storyPopup_story-details-container__RejzA {
  width: 70%;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 24px; }

.storyPopup_story-actions-container__36rqR {
  box-sizing: border-box;
  padding: 10px 0 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: -2.2vw;
  z-index: 2;
  overflow-y: auto; }

.storyPopup_story-description-heading__E8vWK {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-description-content__3y_DH {
  width: 90%;
  text-align: left; }

.storyPopup_feature-description-heading__3UUZH {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.storyPopup_feature-description-content__6w9VP {
  width: 100%;
  text-align: left;
  padding-bottom: 10px; }

.storyPopup_story-history-heading__1CGdQ {
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  height: 16px; }

.storyPopup_story-history-content__3-tuq {
  width: 100%;
  height: 100%;
  overflow-y: auto; }

.storyPopup_feature-image__UQEN4 {
  width: 20px;
  height: 20px;
  margin-right: 10px; }

.storyPopup_selection-container__18Hmg {
  box-sizing: border-box;
  position: absolute;
  bottom: 4vh;
  right: 1.5vw; }

.storyPopup_selection-content__3d_rr {
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  width: 10.249vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.storyPopup_checkbox-container__2l8W5 {
  width: 18px;
  height: 18px; }

.storyPopup_text-container__eaciY {
  padding-left: 4px; }

.storyPopup_card-tag__1VKvL {
  border-bottom-right-radius: 2px; }

.storyPopup_card-tag__1VKvL {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 1.1vw;
  padding-left: 0.1vw;
  padding-right: 0.2vw;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
  border-bottom-left-radius: 2px; }

.storyPopup_card-tag-image__syn6e {
  width: 1.171vw;
  object-fit: cover; }

.storyPopup_card-tag-text__2kfdV {
  display: flex;
  line-height: 2.446vh;
  font-weight: 600;
  font-size: 0.875em; }

.storyPopup_story-select__K2saE {
  position: absolute;
  top: 105px;
  left: 500px;
  width: 10.703vw;
  height: 4.893vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.storyPopup_story-select-icon__1eKcP {
  width: 11px; }

.storyPopup_story-select-text__2s52o {
  font-size: 12px; }

.storyPopup_priority-container__io5Yd {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  border-radius: 2px;
  padding: 0px 0.293vw 0 0.293vw; }

.storyPopup_priority-text__2c3uz {
  display: flex;
  font-weight: 400;
  font-size: 0.625em; }

.storyPopup_priority-image-container__Plyl7 {
  width: 1.171vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.storyHistoryItem_history-item-container__1LUDF {
  width: 100%;
  box-sizing: border-box;
  min-height: 17.6vh;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 370px; }

.storyHistoryItem_history-item-header__8THw3 {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  box-sizing: border-box; }

.storyHistoryItem_history-item-header-text__3-7qv {
  padding: 0 2px; }

.storyHistoryItem_history-item-name-container__1Q6tH {
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px; }

.storyHistoryItem_history-item-name__1lU4s {
  width: calc(100% - 19px); }

.storyHistoryItem_history-item-image__GdHh8 {
  width: 19px;
  height: 19px; }

.storyHistoryItem_history-item-desc__2CqXs {
  width: 100%;
  text-align: left;
  padding-top: 5px; }

.storyHistoryItem_card-bottom-container__2xgva {
  display: flex;
  margin-bottom: 2.8vh;
  align-items: center; }

.storyHistoryItem_story-point-container__2M0HC {
  display: flex; }

.storyHistoryItem_story-point-text__1IVNg {
  display: flex; }

.storyHistoryItem_story-point-number__2H0x7 {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.storyHistoryItem_priority-container__eD_ec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.083vh;
  margin-left: 1vw;
  padding: 1px 0.3vw 1px 0.3vw;
  border-radius: 2px; }

.storyHistoryItem_priority-text__3Js3F {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.blocker_main-component__3HVJ9 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskCheckBox_main-component__ziNoG {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskCheckBox_main-component__v66XO {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskCheckBoxPopUp_main-component__13L5q {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureSwim_main-component__2i_mD {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureSafety_main-component__3oD7t {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureOther_main-component__1W6Cg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureLounging_main-component__2c2aW {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.featureRiver_main-component__1jqrG {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.must_main-component__228qB {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.could_main-component__1TOjN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.should_main-component__1JVNg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mustStoriesCompletedPopup_main-component__1Rf9B {
  width: 49.634vw;
  min-height: 32.422vh;
  border-radius: 10px;
  overflow: hidden; }

.mustStoriesCompletedPopup_popup-header-container__GHPKy {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.mustStoriesCompletedPopup_popup-header-container-content__3NK75 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.mustStoriesCompletedPopup_popup-header-container-background__2mFFt {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.mustStoriesCompletedPopup_popup-details-container__1rebQ {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.mustStoriesCompletedPopup_popup-buttons-container__M__8R {
  width: 100%;
  height: 13.281vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.mustStoriesCompletedPopup_button-container__EFKDE {
  padding: 0 10px;
  display: flex;
  grid-gap: 4vw;
  gap: 4vw; }

.mustStoriesCompletedPopup_header-image__TgyIr {
  padding: 1.5vh 1vw 1vh 1vw; }

.mustStoriesCompletedPopup_header-text__2SD-h {
  text-align: center;
  width: 100%;
  box-sizing: border-box; }

.mustStoriesCompletedPopup_header-desc__39D_w {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.mustStoriesCompletedPopup_popup-desc-container__mQCDz {
  box-sizing: border-box;
  padding: 1vh 3vw 0 3vw;
  text-align: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.simMVPPopup_mvp-popup-container__2knqk {
  width: 46.12vw;
  min-height: 15vh; }

.simMVPPopup_header-container__HJHX6 {
  width: 100%;
  min-height: 12.24vh;
  position: relative; }

.simMVPPopup_content-container__2AQLm {
  width: 100%;
  height: 41vh;
  position: relative;
  box-sizing: border-box;
  padding: 1vh 1vw 4vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: end; }

.simMVPPopup_content-container2__2JroT {
  width: 100%;
  height: 13vh;
  position: relative;
  box-sizing: border-box;
  padding: 1vh 1vw 4vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: end; }

.simMVPPopup_correct-help-container2__35H7E {
  width: 100%;
  height: 100%; }

.simMVPPopup_correct-help-text2__25WN5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.simMVPPopup_cards-container__1-kKf {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 0.8vw;
  gap: 0.8vw; }

.simMVPPopup_header-background-container__L-jR3 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.simMVPPopup_header-content-container__hwPU2 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  box-sizing: border-box; }

@media only screen and (max-height: 760px) {
  .simMVPPopup_header-content-container__hwPU2 {
    padding: 1vh 0vw 1vh 0; } }

.simMVPPopup_header-details-container__3I5Qz {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 1vh;
  box-sizing: border-box; }

.simMVPPopup_heading-content__2hfdM {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 5px; }

.simMVPPopup_desc-content__36aWo {
  width: 100%;
  text-align: left;
  margin-bottom: 1vh; }

.simMVPPopup_heading-text__LCMNn {
  padding-right: 10px;
  box-sizing: border-box; }

.simMVPPopup_correct-help-container__3hi4B {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DADADA;
  border-radius: 2px;
  padding-right: 0.2vw;
  width: 10.176vw;
  margin-bottom: 1vh; }

.simMVPPopup_correct-help-img__3wMD0 {
  width: 24px;
  height: 24px; }

.simMVPPopup_correct-help-text__1QO4a {
  padding-left: 0 0.1vw 0 0.1vw;
  box-sizing: border-box; }

.simMVPPopup_close-container__1og7g {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 0;
  box-sizing: border-box; }

.simMVPPopup_close-icon__3dpvi {
  width: 26px;
  height: 26px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.cross_main-component__h6bC5 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.taskCard_card-container__2oBq7 {
  cursor: pointer;
  height: 20.642vh;
  box-sizing: border-box;
  padding: 1.432vh 0;
  margin: 0.1vw 0vw 1.302vh 0vw;
  position: relative; }

.taskCard_card-child__f3rqK {
  width: 96%;
  height: 91%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.taskCard_card-width__1Gl8V {
  width: 21.154vw; }

.taskCard_card-cursor__2ytW_ {
  cursor: pointer; }

.taskCard_card-popup__2wGwE {
  cursor: default; }

.taskCard_card-header__w2YGO {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0.366vw 0 0.366vw; }

.taskCard_card-heading-content__16s1f {
  width: 85%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden; }

.taskCard_card-status-box__2o6mD {
  width: 1.757vw;
  height: 3.125vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1.042vh;
  right: 0.586vw; }

.taskCard_desc-priority-container__iClAs {
  overflow: hidden; }

.taskCard_progress-container__2ldxv {
  width: 100%;
  height: 1.042vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.366vw 0 0.366vw; }

.taskCard_progress-bar-background__i8s48 {
  width: 100%;
  height: 0.391vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px; }

.taskCard_progress-bar__lr_rg {
  max-width: 100%;
  height: 0.391vh;
  border-radius: 4px; }

.taskCard_story-point-container__22TJo {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.taskCard_story-point-text__1zfxq {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.taskCard_story-point-number__nsZD7 {
  font-weight: 600;
  font-size: 0.625em; }

.taskCard_story-point-container-like-customer__2wnLg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.878vw 0 0.878vw;
  box-sizing: border-box; }

.taskCard_story-point__aHqar {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_story-point-icon__2MRHY {
  display: flex;
  align-items: center; }

.taskCard_like-customer-img__sXoym {
  width: 24px;
  height: 3.125vh; }

.taskCard_storypoints-text__TrzWB {
  padding-right: 10px; }

.taskCard_feature-details-container__IBf2X {
  padding: 0 12px;
  box-sizing: border-box; }

.taskCard_feature-container__941wJ {
  padding: 2px 6px 5px 6px;
  box-sizing: border-box; }

.taskCard_feature-image__E06lc {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 2px; }

.taskCard_feature-name__1D-eY {
  padding-left: 5px;
  box-sizing: border-box; }

.taskCard_feature-desc__nkBVz {
  padding-left: 6px;
  box-sizing: border-box; }

.taskCard_add-task-button-container__1kYTr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }

.taskCard_customer-like-icon__3GPMd {
  width: 1.171vw;
  height: 2.083vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }

.taskCard_add-task-button__1WCKf {
  width: 1.757vw;
  height: 3.125vh;
  border-radius: 50%; }

.taskCard_priorities-button-container__18nR_ {
  position: absolute;
  right: 0vw;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center; }

.taskCard_set-priority-heading__7jd-h {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_priority-buttons__KswE4 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.taskCard_each-priority__NOIj2 {
  border-radius: 16px;
  box-sizing: border-box;
  height: 2.083vh;
  width: 2.489vw;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.taskCard_task-priority-container__2lnap {
  position: absolute;
  right: -0.5vw;
  width: 3.7vw;
  height: 2.083vh;
  box-sizing: border-box;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px; }

.taskCard_task-priority-image__1ft7S {
  width: 0.732vw;
  height: 1.302vh; }

.taskCard_task-priority-letter__1pKTW {
  padding-left: 3px; }

.taskCard_blocker-tag-component__CFQ3R {
  width: 97%;
  height: 2.344vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: auto; }

.taskCard_blocker-image__3xb7I {
  width: 0.878vw;
  height: 1.563vh; }

.taskCard_blocker-text__3GNIl {
  height: 100%;
  padding-left: 0.512vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.taskCard_card-tag__3SS3O {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  top: 0px;
  left: 0.366vw;
  padding: 0vh 0.3vw 0.1vh 0.1vw;
  align-items: center; }

.taskCard_card-tag-image__2Y69T {
  width: 1.171vw;
  display: flex;
  justify-content: center;
  align-items: center; }

.taskCard_card-tag-text__2D6h5 {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75em; }

.taskCard_card-content__1tr_n {
  margin-top: 2vh; }

.taskCard_card-description__3uCtp {
  display: flex;
  line-height: 2.141vh;
  padding-top: 4px;
  padding-right: 0.586vw;
  font-weight: 350;
  font-size: 0.75em;
  margin-left: 0.366vw; }

.taskCard_card-bottom-container__3D6Wq {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 3px;
  width: 95%;
  height: 2.083vh;
  margin-left: 4px;
  margin-bottom: 0.651vh;
  justify-content: space-between; }

.taskCard_priority-container-down-right__3rC8I {
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.taskCard_priority-text__3Ezl6 {
  display: flex;
  font-weight: 400; }

.taskCard_priority-container-up-right__3Iuk1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.taskCard_priority-container-down-left__BapaM {
  position: absolute;
  right: 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  padding-right: 6px;
  box-sizing: border-box;
  border-radius: 2px; }

.taskCard_priority-image-container__eGZ3V {
  width: 1.171vw;
  display: flex;
  align-items: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.addTaskButton_main-component__2iyjS {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.selectedTaskButton_main-component__1FZXL {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.lowPriority_main-component__2AZkq {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.mediumPriority_main-component__2eeod {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.highPriority_main-component__1wB2E {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.blockerResolved_main-component__1U1QD {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.customerLike_main-component__26y48 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@media only screen and (min-width: 1280px) {
  .sprintReport_sprint-report-popup-content-container__26mAp {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1600px) {
  .sprintReport_sprint-report-popup-content-container__26mAp {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (min-width: 1920px) {
  .sprintReport_sprint-report-popup-content-container__26mAp {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 8px;
    padding-left: 8px;
    flex-basis: 100%;
    max-width: 100%; } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReport_oa__3S-GB {
  overflow: auto; }

.sprintReport_unfinshed-task__1SkLd {
  width: 100%;
  height: 100%; }

.sprintReport_vertical-bar__pz8Bv {
  height: 49.021vh;
  width: 1px;
  margin-top: 15px;
  box-sizing: border-box; }

.sprintReport_task-graph__2-yTV {
  width: 100%;
  margin-top: 1vh;
  overflow: hidden; }

.sprintReport_unfinished-container__3JG7p {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; }

.sprintReport_walkthrough-background__1YqBv {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0; }

.sprintReport_sprint-report-container__2wRk5 {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  height: 87.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Open Sans'; }

.sprintReport_sprint-report-content-container__1qe1- {
  width: 100%;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 90%; }

.sprintReport_sprint-report-popup-content-container__26mAp {
  padding: 0; }

.sprintReport_master__1eS7t {
  background-size: cover;
  overflow: auto;
  height: 89vh; }

.sprintReport_sprint-report-component__127Ro {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0; }

.sprintReport_current-sprint-container__3Nk65 {
  margin: auto;
  width: 91vw;
  display: flex;
  justify-content: space-between;
  margin-top: 1vh; }

.sprintReport_report-info-container__2ZYe7 {
  width: 96vw;
  height: 73.047vh;
  background: white;
  margin: auto;
  margin-top: 2vh;
  border-radius: 8px; }

.sprintReport_report-content__33eSR {
  overflow-y: auto;
  overflow-x: hidden;
  height: 58.031vh;
  margin-top: 1vh; }

.sprintReport_report-content__33eSR::-webkit-scrollbar-track {
  background: transparent; }

.sprintReport_tabs__31vX0 {
  height: 10.417vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 4.587vw 0 4.587vw; }

.sprintReport_tabs-popup__1M8kP {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7vh 3.8vw 0 4.587vw;
  box-sizing: border-box; }

.sprintReport_text__Y3DAE {
  width: 91vw;
  font-family: 'Open Sans';
  margin: 1vh auto;
  padding: 1.7vh 2vw 1.7vh 2vw;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  overflow: hidden; }
  .sprintReport_text__Y3DAE div:nth-child(2) {
    margin-top: 0vh; }

.sprintReport_info__11F5L {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
  margin: 2vh 4.587vw; }

.sprintReport_info-left-container__Cohyn {
  display: flex;
  flex-direction: column;
  width: 55.782vw;
  box-sizing: border-box;
  padding-right: 1.3vw; }

.sprintReport_metrics__C8gFe {
  box-sizing: border-box;
  width: 100%; }

.sprintReport_met-name__3W6Vw {
  display: flex;
  height: 4.427vh;
  padding: 0.521vh 16px 0.521vh 1vw;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.sprintReport_heading__kHIEZ {
  width: 100%;
  display: flex;
  height: 4.427vh;
  padding: 4px 16px 4px 1vw;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.sprintReport_game-completion-wrappper__2Z1LG {
  width: 91.801vw;
  margin: auto;
  padding: 0 0.51vw 0 0.51vw;
  border-radius: 8px;
  position: relative; }

.sprintReport_footer-svg1__YEfmc {
  position: absolute;
  z-index: -1;
  bottom: 7.5vh;
  width: 88.3vw;
  height: 3vh; }

.sprintReport_footer-svg2__37KcU {
  position: absolute;
  z-index: -1;
  width: 56vw;
  height: 9vh;
  bottom: 1.5vh; }

.sprintReport_footer-svg3__3WZ5L {
  position: absolute;
  z-index: 0;
  bottom: -1vh;
  right: 0; }

.sprintReport_customers-speak___Tws4 {
  width: 90%; }

.sprintReport_task__2fGKv {
  width: 38.7vw;
  box-sizing: border-box;
  padding-left: 15px; }

.sprintReport_task-row__2_xJq {
  display: flex;
  flex-direction: column;
  width: 100%; }

.sprintReport_heading-text__1y_GZ {
  padding-right: 10px; }

.sprintReport_game-completed__1vmLE {
  background: #FFFFFF;
  width: 88.3vw;
  height: 34.245vh;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 5vh; }

.sprintReport_img__14LT0 {
  width: 21.449vw;
  height: 28.516vh;
  margin-left: 2vw; }

.sprintReport_content__1NLB2 {
  margin-left: 1vw;
  width: 62vw; }

.sprintReport_title__wYWXS {
  max-width: 100%; }

.sprintReport_ms__142KE {
  padding: 0.5vh 0px 4vh 0px;
  max-width: 100%; }

.sprintReport_download-button__2sTWo {
  background: linear-gradient(114.68deg, #FFFFFF 3.7%, #FFFFFF 100%);
  opacity: 0.9;
  border: 1px solid #232882;
  border-radius: 4px;
  width: 220px;
  height: 46px;
  color: #232882;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
  cursor: pointer; }

.sprintReport_walkthrough-tabs-container__2sCAz {
  padding: 3vh 0 1vh 0; }

.sprintReport_flow__3hVQs {
  position: relative; }

.sprintReport_buttons-container__3GNCl {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.sprintReport_single-button-container__3_9qI {
  margin-right: 1vw;
  width: 20vw; }

.sprintReport_help-text-report__rFFUV {
  height: 20px;
  padding-top: 4px;
  width: 300px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.gameComplete_main-component__Zi9ML {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DADADA; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintFooter_sprint-footer-container__3H0Q8 {
  width: 100%;
  height: 5.8vh;
  box-sizing: border-box;
  position: relative; }

.sprintFooter_sprint-footer-background__15u3K {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start; }

.sprintFooter_sprint-footer-component-content__1x-4g {
  width: 100%;
  height: 5.8vh;
  z-index: 2;
  position: absolute;
  bottom: -1vh;
  right: 4.6vw; }

.sprintFooter_sprint-footer-component__3PEY9 {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.history_history-container__3difO {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0px;
  height: 50%; }

.history_history-content__MPmF3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.2vw;
  cursor: pointer;
  height: 100%; }

.history_history-name__2lado {
  padding-left: 0.5vw;
  cursor: pointer; }

.history_history-image__aCF-t {
  width: 1.757vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.historySvg_main-component__3jzKR {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sendEmailReport_email-report-container__2CV2d {
  width: 100%;
  margin-right: 20px;
  display: flex;
  height: 5.208vh; }

.sendEmailReport_email-heading__ZiaZf {
  width: 100%;
  padding-bottom: 4px; }

.sendEmailReport_email-input-container__1OmQ6 {
  width: 23vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.sendEmailReport_email-input__3r2xO {
  width: calc(100% - 25px);
  height: 100%; }

.sendEmailReport_email-send__2iTyQ {
  margin-left: 1vw; }

.sendEmailReport_email-input-element__f2PHr {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: white; }

.sendEmailReport_email-input-element__f2PHr:focus {
  outline: none; }

.sendEmailReport_email-status-bar__24PtJ {
  width: 100%;
  padding-top: 4px;
  box-sizing: border-box; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.emailSend_main-component__pfapY {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.gameIncomplete_main-component__Fq6Cc {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DADADA; }

.tabs_tab-container__dYDjm {
  display: flex;
  height: 5.208vh;
  border-bottom: 2px solid #DADADA;
  width: calc(100% - 11.468vw); }

.tabs_tab-container-popup__EeehF {
  display: flex;
  height: 5.208vh;
  border-bottom: 2px solid #989898;
  width: 82%; }

.tabs_tab__4dGw_ {
  height: 100%;
  width: 6.149vw;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  color: #FFFFFF; }

.tabs_selected__19XoF {
  color: #EE7748;
  font-weight: 600;
  font-size: 1em;
  border-bottom: 2px solid #EE7748; }

.tabs_popup__1MOe6 {
  color: black; }

.tabs_number__1tPX_ {
  padding-top: 1.3vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.metric_big__3EQca {
  width: 24.5vw;
  height: 9.115vh;
  background: #FFFFFF;
  border-radius: 1px;
  margin: 1vh 0px 0vh 0px;
  position: relative;
  border: 1px solid #989898;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 0.5vw; }

.metric_name-container__4JQRJ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1vh 0px 0px 8px;
  box-sizing: border-box; }

.metric_name__2FV00 {
  padding-right: 5px; }

.metric_completion__3YQmp {
  line-height: 4vh;
  padding: 0px 0px 0px 8px; }

.metric_graph-box__2iGie {
  padding: 5px 0px 0px 8px; }

.metric_graph__2kD2W {
  background: #232882;
  opacity: 0.1;
  border-radius: 2px;
  height: 4px;
  width: 380px; }

.metric_graph-overlay__332bZ {
  background: rgba(35, 40, 130, 0.9);
  border-radius: 2px;
  height: 4px;
  margin-top: -4px; }

.metric_small__3D-zs {
  width: 7.25vw;
  height: 9.115vh;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #989898;
  position: relative;
  box-sizing: border-box;
  margin: 1vh 0px 0vh 0px; }

.metric_small-child__1t_NR {
  width: 6.2vw;
  height: 7.1vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.metric_mb__2zeMe {
  height: 9.115vh;
  min-width: 12.5vw; }
  .metric_mb__2zeMe .metric_b-change__BLpFI {
    bottom: 1.8vh;
    right: 1vw; }

.metric_small-boxes-bottom__2vhUm {
  flex-direction: column; }

.metric_metrics-resources__2-JIc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 1vw;
  gap: 1vw; }

.metric_small-boxes__3uA0- {
  display: flex;
  margin-bottom: 1vh;
  grid-gap: 0.5vw;
  gap: 0.5vw;
  justify-content: space-between; }

.metric_big-box__1Oe5U {
  display: flex;
  width: 40vw; }

.metric_re-box-image__6Bad_ {
  width: 1.757vw;
  height: 3.125vh; }

.metric_met-name__3MYbd {
  display: flex;
  height: 4.427vh;
  padding: 4px 16px 4px 1vw;
  border-radius: 4px;
  box-sizing: border-box;
    box-sizing-display: flex;
    box-sizing-align-items: center; }

.metric_arrow__35JQM {
  width: 1.171vw; }

.metric_arrow-small__fGzrm {
  width: 0.878vw;
  height: 0.781vh;
  padding-top: 2px;
  padding-right: 3px; }

.metric_b-change-small__3cbxE {
  display: flex;
  position: absolute;
  bottom: 14px;
  right: 8px;
  font-size: 0.625em;
  color: rgba(35, 40, 130, 0.7);
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_b-change__BLpFI {
  display: flex;
  position: absolute;
  bottom: 2.446vw;
  right: 2.294vh;
  font-size: 0.625em;
  color: rgba(35, 40, 130, 0.7);
  border-radius: 16px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.metric_re-box__znTWp {
  background: white;
  border-radius: 4px;
  width: 13.177vw;
  height: 9.115vh;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 0.5vh;
  box-sizing: border-box;
  border: 1px solid #989898; }

.metric_re-completion__Skn62 {
  padding: 0px 0px 0px 1.025vw;
  margin-top: -0.5vh; }

.metric_re-name-container__1leoP {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0px 0px 1.025vw; }

.metric_re-name__68kgK {
  padding-right: 5px; }

.metric_value-red__109oS {
  color: #D11021;
  box-sizing: border-box; }

.metric_value-green__Y_Glk {
  color: #0B7F3D;
  box-sizing: border-box; }

.metric_sim-mvp-container__8lXeF {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  height: 100%; }

.metric_sim-mvp-content__bPO9P {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.metric_sim-mvp-details__1qtWQ {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.metric_sim-mvp-top__zGB47 {
  width: 100%;
  display: flex; }

.metric_sim-mvp-text__25f9c {
  padding-right: 5px; }

.metric_sim-mvp-value__1rusp {
  margin-right: 6px; }

.metric_sim-mvp-bottom__1E1Ec {
  width: 100%; }

.metric_sim-mvp-progress-bar-container__3ZGl9 {
  width: 100%;
  border-radius: 4px; }

.metric_sim-mvp-progress-bar__3UYBD {
  height: 7px; }

.metric_sim-mvp-progress-bar__3UYBD {
  height: 7px;
  border-radius: 3px;
  display: flex; }

.metric_sim-mvp-arrow-container__1dUMt {
  display: flex; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.upArrow_main-component__2cjQq {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.downArrowReport_main-component__1reAQ {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.calendar_main-component__SJet2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_story__2s-Xd {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 17.3vw;
  height: 14.9vh;
  padding: 1vh 0.4vw;
  box-sizing: border-box;
  position: relative;
  border: 2px solid #DADADA;
  box-shadow: 0px 4px 4px 0px #0000001A; }

.stories_stories__2CTck {
  width: 100%;
  padding: 0vh 0.7vw 0.3vh 0.7vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-row-gap: 1vh;
  row-gap: 1vh;
  overflow: auto;
  height: 15.2vh;
  margin-top: 0.5vh; }

.stories_card-name__b2uW2 {
  font-weight: 600;
  font-size: 0.75em;
  padding: 0px 0px 5px 0px;
  margin-top: 2vh; }

.stories_card-description__k26_9 {
  font-weight: 600;
  font-size: 0.75em;
  padding: 0px 0px 10px 0px; }

.stories_info__1t2LG {
  font-size: 12px;
  color: rgba(3, 26, 32, 0.5);
  margin-top: 3px; }

.stories_bottom__28-io {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0vh;
  width: 95%; }

.stories_priority__LjPu4 {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 33px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_stories-heading__1v4k4 {
  box-sizing: border-box;
  padding: 1vh 0 0 0.7vw; }

.stories_priority_arabic__2XnbW {
  border: 1px solid #42BAFF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 60px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_priority-image__3lC_D {
  width: 10px;
  height: 10px;
  margin-right: 3px; }

.stories_priority-letter__3ju20 {
  color: #42BAFF;
  font-weight: bold;
  font-size: 10px; }

.stories_wrap__OE8FQ {
  width: 100%;
  background-color: #474747B2;
  height: 19.922vh;
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px; }

.stories_wrap-popup__2FOpj {
  width: 100%;
  height: 19.922vh;
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px;
  border: 1px solid #989898;
  padding-right: 0.3vw;
  box-sizing: border-box; }

.stories_customers-speak__156Hn {
  width: 70%; }

.stories_priority-container__5uTse {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0px 0.293vw;
  margin-bottom: 5px;
  box-sizing: border-box;
  border-radius: 2px; }

.stories_priority-text__X4URw {
  display: flex; }

.stories_card-tag__1dcRy {
  padding: 1px 0.3vw 0.3vh 0.1vw;
  grid-gap: 0.2vw;
  gap: 0.2vw; }

.stories_card-tag__1dcRy {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0.45vw;
  border-bottom-right-radius: 2px; }

.stories_card-tag__1dcRy {
  border-bottom-left-radius: 2px;
  align-items: center; }

.stories_card-tag-image__3LA9r {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.stories_card-tag-text__1ZVoc {
  display: flex;
  align-items: center;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1em; }

.stories_priority-image-container__zZ5x2 {
  width: 1.171vw;
  display: flex;
  align-items: center; }

.retro_wrap__lo7TJ {
  overflow: auto;
  width: 100%;
  background-color: #474747B2;
  height: 46.745vh;
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px;
  padding: 1vh 0.5vw 1vh 0.5vw;
  box-sizing: border-box; }

.retro_wrap-popup__16MXF {
  overflow: auto;
  width: 100%;
  height: 46.745vh;
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column-gap: 1vw;
  column-gap: 1vw;
  border-radius: 4px;
  border: 1px solid #989898;
  padding: 1vh 0.5vw 1vh 0.5vw;
  box-sizing: border-box; }

.retro_retro-content__2s9PA {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5vh;
  gap: 1.5vh;
  overflow-y: auto;
  overflow-x: hidden; }

.retro_single-retro-point__zEFao {
  display: flex;
  grid-gap: 0.8vw;
  gap: 0.8vw; }

.retro_retro-message__1i55y {
  width: 32vw; }

.retro_met-name__1cO5y {
  display: flex;
  height: 4.427vh;
  padding: 4px 16px 4px 1vw;
  border-radius: 4px;
  box-sizing: border-box; }

.retro_met-name__1cO5y {
  align-items: center;
  width: 35.2vw; }

.retro_image-container__RMuOs {
  width: 1.769vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.retroPoint_main-component__1PpiW {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.cust_comment__NehqT {
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 25px;
  color: rgba(3, 26, 32, 0.7);
  min-height: 34px; }

.cust_heading__2TGbr {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(35, 40, 130, 0.6); }

.cust_top__3Rz2h {
  display: flex;
  margin-bottom: 5px; }

.cust_avatar-wrap__1aaXI {
  margin-left: 28px;
  margin-top: -6px; }
  .cust_avatar-wrap__1aaXI img {
    height: 35px;
    width: 35px; }

.cust_comment-container__1Vljx {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(35, 40, 120, 0.2);
  padding: 5px 0; }

.cust_comment-image__2af7H {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin: 2px 10px 0 10px;
  position: relative; }

.cust_normal-chat-image__3bLBi {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: -7px;
  left: -12px; }

.completion_legend__2gekK {
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 0.5vh; }

.completion_wrap__2Imjz {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.5vh 0vw 0.5vh 0vw;
  height: 19.922vh;
  width: 16.4vw;
  margin-top: 1vh;
  box-sizing: border-box;
  border-radius: 4px; }

.completion_semi-donut-container__2CISb {
  --percentage: 0;
  margin: auto;
  width: 14.3vw;
  height: 13.3vh;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box; }

.completion_semi-donut__2lnQT {
  position: absolute;
  top: 0;
  left: 0;
  width: 14.3vw;
  height: 14.3vw;
  border: 3vw solid;
  box-sizing: border-box;
  border-radius: 50%;
  border-color: #ECECEC #ECECEC var(--fill) var(--fill);
  transform: rotate(calc( 1deg * ( -45 + var(--percentage) * 1.8 ))); }

.completion_line-shadow__za_cY {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.15vw;
  height: 1px;
  background: transparent;
  transform-origin: 0 50%;
  transform: rotate(-135deg);
  box-shadow: -1px -1px 0.2vw 0.05vw #00000026, 0vw -2px 0.2vw 0vw #00000026 inset; }

.completion_semi-circle__3PsmP {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 50%; }

.completion_semi-donut-outer-shadow__2AJLp {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 14.3vw;
  height: 14.3vw;
  z-index: 10;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0vw 0.191vw 0.2vw 0vw #00000026 inset; }

.completion_semi-donut-inner-shadow__1K5lC {
  position: absolute;
  top: 3vw;
  left: 3vw;
  width: 8.3vw;
  height: 8.3vw;
  z-index: 9;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0vw -0.191vw 0.2vw 0vw #00000026; }

.completion_semi-donut-left-shadow__3tghf {
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 2.9vw;
  height: 1px;
  z-index: 9;
  box-shadow: 0vw -0.1vw 0.2vw 0.03vw #00000026; }

.completion_semi-donut-right-shadow__2XsVm {
  position: absolute;
  bottom: 0;
  right: 1px;
  width: 2.9vw;
  height: 1px;
  z-index: 9;
  box-shadow: 0px -0.1vw 0.2vw 0.03vw #00000026; }

.completion_graph__3BKZK {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center; }

.completion_square__2_NBs {
  width: 12px;
  height: 12px;
  margin-right: 4px; }

.completion_leg-wrap__10C8d {
  display: flex;
  align-items: center;
  margin-left: 0.5vw; }

.completion_green__O4u7v {
  background: #B5F000; }

.completion_white__2IIkP {
  background: #FFFFFF; }

.completion_grey__1HIlt {
  background: #DADADA; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.circularProgressBar_bar-container__21RjV {
  width: 100%;
  height: 100%; }

.circularProgressBar_progressbar-container__3tECz {
  position: relative; }

.circularProgressBar_progress-number__QMDkb {
  font-size: 28px;
  position: absolute;
  width: 100%;
  top: 35%;
  left: 20%;
  padding: 0;
  margin: 0; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReportPopupHeader_header-container__3oeSC {
  width: 100%;
  min-height: 5.208vh;
  position: relative;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  overflow: hidden; }

.sprintReportPopupHeader_header-background-container__1w-8- {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sprintReportPopupHeader_header-content-container__22m0P {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintReportPopupHeader_header-details-container__120YX {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2vw;
  box-sizing: border-box; }

.sprintReportPopupHeader_story-name__2G9W5 {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintReportPopupHeader_close-container__EDxHP {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-end;
  margin-right: 1vw; }

.sprintReportPopupHeader_close-icon__WMDrO {
  width: 1.757vw;
  height: 3.125vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintReportPopupContainer_sprint-report-popup-container__1LmVH {
  width: 96vw;
  height: 73.047vh;
  border-radius: 8px;
  overflow: hidden;
  position: relative; }

.sprintReportPopupContainer_sprint-report-popup-header__85Ejq {
  width: 100%;
  height: 73.047vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.productLaunchPopup_main-component__1Tzmd {
  width: 49.634vw;
  min-height: 32.422vh;
  border-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container__2uN8V {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden; }

.productLaunchPopup_popup-header-container-content__VnY1T {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; }

.productLaunchPopup_popup-header-container-background__TuJAn {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%; }

.productLaunchPopup_popup-details-container__2Lby0 {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_popup-buttons-container__2D6J0 {
  width: 100%;
  height: 13.281vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; }

.productLaunchPopup_button-container__1u3KE {
  padding: 0 10px; }

.productLaunchPopup_header-image__3-D6V {
  padding: 1.5vh 1vw 1vh 1vw; }

.productLaunchPopup_header-text__A6I0g {
  text-align: center;
  width: 100%;
  box-sizing: border-box; }

.productLaunchPopup_header-desc__1yAKK {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 10px 20px; }

.productLaunchPopup_popup-desc-container__2CWiw {
  box-sizing: border-box;
  padding: 1vh 1vw 0 1vw;
  text-align: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.leaderboardPopup_leaderboard-popup-container__3T1pK {
  width: 67.2vw;
  height: 68.7vh;
  box-sizing: border-box; }

.leaderboardPopup_header-container__lqQEw {
  width: 100%;
  height: 8vh;
  z-index: 2;
  position: relative; }

.leaderboardPopup_header-background-container__3Lhd4 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.leaderboardPopup_title__2VCWm {
  padding: 2.6vh 0px 0px 1.4vw;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0; }

.leaderboardPopup_close-icon__2FKsF {
  position: absolute;
  top: 1.5vh;
  right: 1vw;
  height: 5vh;
  z-index: 1;
  margin-top: 0.4vh; }

.leaderboardPopup_refresh-container__1U4-X {
  position: absolute;
  top: 1.7vh;
  right: 7vw;
  z-index: 1;
  margin-right: 0.5vw; }

.leaderboardPopup_content__3C9e2 {
  width: 100%;
  height: calc(100% - 116px);
  position: relative;
  top: 0;
  left: 0; }

.leaderboardPopup_table-header-container__3KluO {
  width: 100%;
  height: 4vh;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 2vh 0px 0px 0px; }

.leaderboardPopup_position__1O_gt {
  width: 8vw;
  box-sizing: border-box; }

.leaderboardPopup_user-container__3_PLy {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  padding-right: 10px; }

.leaderboardPopup_username-heading__3L650 {
  width: 39vw;
  box-sizing: border-box;
  padding-left: 1vw; }

.leaderboardPopup_username__1Knqn {
  width: 41vw;
  box-sizing: border-box;
  padding-left: 1vw; }

.leaderboardPopup_days-remaining__3lkD1 {
  width: 10vw;
  box-sizing: border-box; }

.leaderboardPopup_mvp__IUsn8 {
  box-sizing: border-box; }

.leaderboardPopup_position-header__10HJA {
  padding-left: 2.4vw; }

.leaderboardPopup_position-body__GMOfZ {
  display: flex;
  align-items: center;
  justify-content: center; }

.leaderboardPopup_mvp-body__BD1zR {
  text-align: right; }

.leaderboardPopup_table-body-container__1OtR8 {
  height: 49vh;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1vh; }

.leaderboardPopup_table-row__2kSgA {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 4vh;
  padding-top: 2vh; }

.leaderboardPopup_seperation-line__2O8Kj {
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 0 16px;
  height: 1px; }

.leaderboardPopup_table-container__2NwlF {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

.leaderboardPopup_button-wrapper__3ZTwy {
  display: flex;
  align-items: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.closedButton_button-style__JpuNG {
  box-sizing: border-box; }

.closedButton_button-style__JpuNG {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  text-transform: uppercase;
  border-radius: 4px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.knowYourTeam_main-component__3MNJo {
  width: 54.539vw;
  position: relative; }

.knowYourTeam_header-container__3GDS9 {
  padding: 0.5vw 0.5vw 0.3vw 2vw;
  display: flex;
  justify-content: space-between;
  margin-top: 8vh;
  align-items: center;
  height: 6.771vh; }

.knowYourTeam_close-icon__3ihiM {
  position: relative;
  z-index: 1;
  margin-right: 4vw;
  height: 4.167vh; }

.knowYourTeam_popup-body__ek289 {
  padding: 0.5vw 0.5vw 0.3vw 2vw;
  width: 46.925vw;
  height: 75vh;
  overflow-x: none; }

.knowYourTeam_footer-svg__7AsUe {
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: -0.5vh; }

.knowYourTeam_team-members-container__3WuaY {
  display: flex;
  justify-container: start;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  margin-top: 2vh; }

.knowYourTeam_card-style__d3EyS {
  margin: 2px 2px;
  width: 23.133vw;
  text-align: center; }

.knowYourTeam_card-header__MRjwv {
  display: flex;
  justify-content: space-around; }

.knowYourTeam_tm-member-details__2O8Ld {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5vh; }

.knowYourTeam_img-wrapper__2owwb {
  height: 16.2vh;
  width: 8.785vw; }

.knowYourTeam_tm-member-text-details__1mnEb {
  height: 7.943vh;
  display: flex;
  margin-top: 1vh; }

.knowYourTeam_name-details__1RfrZ {
  font-weight: bold; }

.knowYourTeam_tm-morale-stats__11_zN {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 40px; }

.knowYourTeam_tm-skill-stats__2wznX {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 45px; }

.knowYourTeam_stat-value__2R3mZ {
  padding: 1.5px 12px;
  border-radius: 10px; }

.knowYourTeam_skill-stats-header__3Dzdc {
  display: flex;
  align-items: center; }

.knowYourTeam_skill-icon__2nG60 {
  width: 11px;
  height: 11px;
  margin-right: 5px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.knowYourCustomers_main-component__25fOR {
  width: 814px;
  height: calc(100% - 38px);
  position: relative; }

.knowYourCustomers_header-container__kk_R8 {
  border-radius: 0;
  padding: 24px 30px 16px 30px;
  display: flex;
  justify-content: space-between; }

.knowYourCustomers_close-icon__1-B6t {
  position: absolute;
  right: 2vw;
  z-index: 1;
  top: 2.3vh; }

.knowYourCustomers_popup-body__3awVR {
  padding: 12px 24px;
  height: calc(100% - 240px);
  overflow: auto; }

.knowYourCustomers_card-style__3tlbu {
  padding: 20px;
  margin: 16px 0; }

.knowYourCustomers_card-header__3Zocn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px; }

.knowYourCustomers_tm-member-details__ePoC_ {
  display: flex; }

.knowYourCustomers_name-details__3KeVU {
  margin-left: 10px; }

.knowYourCustomers_footer-container__1OKJH {
  display: flex;
  align-items: center;
  bottom: 10px;
  padding: 5px 15px;
  box-sizing: border-box;
  height: 170px; }

.knowYourCustomers_footer-image__2u9qv {
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.knowYourCustomers_cpo-details__2WCZF {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.actionLoader_loader-container__1GCKC {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.actionLoader_svg-container__3KrMo {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: actionLoader_loadingMove__3jFtG 3s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.actionLoader_svg-wrapper__1icBz {
  width: 100%;
  height: 100%; }

@keyframes actionLoader_loadingMove__3jFtG {
  0%, 100% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(180deg); } }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.eventLoader_loader-container__2h2wk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.eventLoader_svg-container__3D4EI {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: eventLoader_loadingMove__oVA4u 1.5s infinite;
  width: 103px;
  height: 92px;
  margin-bottom: 10px; }

.eventLoader_svg-wrapper__LZNSM {
  width: 100%;
  height: 100%; }

@keyframes eventLoader_loadingMove__oVA4u {
  from {
    transform: rotate(0deg); }
  10% {
    transform: rotate(15deg); }
  20% {
    transform: rotate(-15deg); }
  30% {
    transform: rotate(0deg); }
  to {
    transform: rotate(0deg); } }

.step-1-container {
    width: auto;
    height: 3.26vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 0 !important
}

.step-6-container {
    width: auto;
    height: 3.26vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 0 !important
}

.step-5-container {
    width: auto;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-5-container-text-box {
    text-align: initial;
    width: 100%;
}

.step-5-container-header {
    font-weight: 600;
    width: 100%;
    font-size: 1em;
    line-height: 2.8vh;
    color: black;
}

.step-5-container-text {
    font-size: 1em;
    font-weight: 500;
    width: 28vw;
    line-height: 2.683vh;
    color: #141414;
    text-align: left;
    padding-bottom: 0px !important
}

.step-1-container-text {
    font-weight: 500;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    width: 25vw;
    text-align: left;
}

.step-container-next {
    margin-top: 1.30vh;
    margin-left: 0.8vw;
    height: 3.125vh;
    padding-bottom: 0 !important;
}

.step-container-back {
    margin-top: 1.30vh;
    margin-left: 2.92vw;
    height: 4.17vh;
}

.step-1-container-arrow {
    margin-left: 0.4vw;
    width: 1.767vw;
    padding-bottom: 0 !important;
}

.step-arrow {
    display: flex;
    margin-left: 1vw;
    height: 4.17vh;
    padding-bottom: 0 !important
}

.step-button {
    display: flex;
    position: absolute;
    justify-content: center;
    top: 45vh;
    left: 33%;
    font-size: 1.171vw;
}

.step-button button {
    background-color: rgba(238, 119, 72, 1);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 1.30vh 2.92vw;
    font-size: 1.17vh;
    cursor: pointer;
}

.step-1-container-text-box {
    text-align: initial;
    margin-top: 10px;
    padding-bottom: 0 !important
}

.step-1-container-header {
    font-weight: 600;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    /* opacity: 0.8 */
}

.img-text {
    padding: 0 !important;
    font-size: 0.75em;
}

.step-4-container {
    display: flex;
}

.step-2-blocker-container {
    display: flex;
    height: 16.229vh;
    width: 12.275vw;
}

.step-2-container-text-box{
    padding-right: 10px;
    width: 29.283vw;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-blocker-container-text{
    font-weight: 500;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    text-align: left;
}

.step-2-container-text {
    font-weight: 500;
    width: 35.432vw;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    text-align: left;
}

.step-2-container-text-action {
    font-size: 1em;
    width: 22vw;
    color: #141414;
    line-height: 2.683vh;
}

.step-2-container-text-action-list {
    font-size: 1em;
    color: black;
    line-height: 2.683vh;
}

.step-2-container {
    width: 37.775vw;
    height: auto;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-container {
    display: flex;
    flex-direction: column;
    grid-gap: 3vh;
    gap: 3vh;
}

.step-back {
    display: flex;
    position: absolute;
    right: 15px;
    width: 1.767vw;
    bottom: 0px;
}

.step-container-next-action-list {
   position: absolute;
   right: 1vw;
   bottom: 1vh;
   width: 1.767vw;
}

.step-container-event {
    position: absolute;
    right: 0vw;
    bottom: -3vh;
    width: 1.767vw;
 }

.step-1-container-action-list {
    width: 33.775vw;
    font-weight: 500;
    height: 15vh;
    font-size: 1em;
}

.step-1-container-action {
    width: 33.775vw;
    font-weight: 500;
    height: 12vh;
    font-size: 1em;
}

.step-1-container-arrow-report {
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-arrow-report-velocity {
    padding-bottom: 0 !important;
    width: 1.767vw;
    padding-right: 0.3vw;
}

.step-1-container-arrow-report-back {
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-single-action {
    padding-right: 1vw;
    font-weight: 500;
    font-size: 1em;
    width: 25.624vw;
}

.step-1-container-action-response {
    padding-right: 1vw;
    font-weight: 500;
    font-size: 1em;
}

.step-1-container-arrow-report-mvp-back{
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-arrow-report-mvp{
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-2-container-text-box-action-list{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-2-container-text-box-action-response{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important;
    width: 64.495vw;
}

.step-2-container-text-box-blocker-response{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important;
    width: 28.624vw;
}

.step-2-container-text-box-retro{
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-2-container-text-box-report{
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.tool-tip {
    position: absolute;
    bottom: -22.642vh;
    background-color: white;
    left: 0px;
    padding: 20px 15px 25px 15px;
    border-radius: 5px;
    max-width: 100%;
    box-sizing: border-box;
}

.tooltip-blocker {
    position: absolute;
    bottom: 25vh;
    background-color: white;
    left: calc(72.5vw + 72px);
    padding: 10px 0.5vw 10px 0.5vw;
    border-radius: 5px;
    max-width: 15.154vw;
    height: 11.328vh;
    box-sizing: border-box;
    padding-bottom: 10px !important;
}

.arrow {
    display: inline-flex; 
    position: absolute; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    top: 28.5vh;
    left: -19px;
}

.arrow-blocker {
    display: inline-flex; 
    position: absolute; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    bottom: 29vh;
    left: calc(72.5vw + 53px);
}

.spotlight {
    border-radius: 4px; 
    position: absolute; 
    background-color: rgba(128, 128, 128);
    width: 21.4vw; 
    height: 20.9vh; 
    left: calc((100vw - 88.287vw) / 2); 
    pointer-events: auto; 
    top: 61.2vh; 
    transition: opacity 0.2s ease 0s;
    z-index: 1000;
}

.step-1-container-velocity {
    width: 35.762vw;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-1-container-unfinshed {
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-1-container-efficiency {
    width: 34.81vw;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-1-container-event{
    width: 28.624vw;
    height: 8.549vh;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    position: relative
}

.step-2-container-event{
    width: 36vw;
    height: 14.5vh;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    position: relative;
    font-weight: 500;
}



.step-1-container-mvp {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-2-container-text-report{
    display: flex;
    align-items: center;
    grid-gap: 0.5vw;
    gap: 0.5vw;
    font-size: 1em;
    font-weight: 500;
    line-height: 2.683vh;
    color: black;
}

.step-1-container-final {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-1-container-final1 {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.text-box-final-sprint {
    padding: 0 !important;
    text-align: initial;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.teamMembers_tm-container__1h36D {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.126vw;
  height: 50%;
  cursor: pointer; }

.teamMembers_tm-content__2IeQL {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 1.757vw;
  height: 3.125vh;
  box-sizing: border-box; }

.teamMembers_tm-name__1sj1w {
  padding: 0 0 0 0.5vh;
  width: calc(100% - 3.4vw);
  box-sizing: border-box; }

.teamMembers_tm-image__369nl {
  width: 1.757vw;
  height: 3.125vh; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.customers_customers-container__2GTSK {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 9.862vw;
  height: 70%;
  cursor: pointer; }

.customers_customers-content__3BlSg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 18px;
  height: 18px;
  box-sizing: border-box; }

.customers_customers-name__3n-_Q {
  padding: 0 0 0 5px;
  width: calc(100% - 36px);
  box-sizing: border-box; }

.customers_customers-image__ApKg4 {
  width: 14px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintComponent_sprint-container__1G-Jr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 14.422vw;
  height: 50%; }

.sprintComponent_sprint-content__t66t9 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.6vh 0.2vw 0.6vh 0.4vw; }

.sprintComponent_sprint-name-desc-container__2kSA- {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sprintComponent_sprint-name-day-container__2JfH1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 3.9vw;
  max-height: 3.385vh; }

.sprintComponent_sprint-name__1RDVd {
  padding-right: 1px;
  font-size: 0.625em; }

.sprintComponent_sprint-day-progress-container__2CIMh {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.385vh; }

.sprintComponent_sprint-day-container__11N77 {
  width: 100%;
  height: 100%;
  font-size: 1em;
  margin-top: -5px; }

.sprintComponent_sprint-progress-container__147KZ {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-left: 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintComponent_sprint-day__13oUy {
  width: 0.35vw;
  height: 100%;
  margin-right: 0.366vw;
  border-radius: 5px; }

.sprintComponent_sprint-current-day__2jRPY {
  width: 0.35vw;
  height: 100%;
  margin-right: 0.366vw;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.stepNext_main-component__3JuQn {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.stepBack_main-component__3g8IJ {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.introPopup_intro-popup-container__2wrEz {
  width: 88.287vw;
  height: 82.552vh;
  box-sizing: border-box; }

.introPopup_intro-popup-container2__3rT9n {
  width: 88.287vw;
  height: 42.708vh;
  box-sizing: border-box; }

.introPopup_header-container__1BSv7 {
  width: 100%;
  height: 7.292vh;
  z-index: 2;
  position: relative;
  top: 0;
  left: 0; }

.introPopup_header-background-container__3VSOS {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

.introPopup_header-content__1Levh {
  position: relative;
  padding-top: 1.5vh;
  z-index: 1; }

.introPopup_title__OG7Z2 {
  position: absolute;
  left: 2vw;
  z-index: 1; }

.introPopup_close-icon__1H1J_ {
  position: absolute;
  right: 1vw;
  top: 1.6vh;
  z-index: 1; }

.introPopup_content__1-pw_ {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column; }

.introPopup_intro-content-container__2sx2Y {
  z-index: 3;
  overflow-y: auto;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: row; }

.introPopup_button-container__10HZz {
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: absolute;
  bottom: 6vh;
  right: 1vw; }

.introPopup_button-container2__EX1_K {
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: absolute;
  bottom: 3.6vh;
  right: 1vw; }

.introPopup_content-background-1__2QebH {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0; }

.introPopup_content-background-2__2jQMo {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0; }

.introPopup_slide-intro__1x01C {
  padding-top: 2vh;
  margin: 0.1vh 1vw 1vw 2vw; }

.introPopup_intro-content-container2__2Q1dR {
  display: flex;
  position: absolute;
  top: 25vh;
  right: 0.4vw;
  z-index: 1; }

.introPopup_transcript-container__129it {
  padding: 15px 50px;
  box-sizing: border-box;
  width: 100%; }

.introPopup_transcript-show__3KL1D {
  padding-top: 5px;
  display: block;
  width: 100%; }

.introPopup_transcript-hidden__DP_Nu {
  padding-top: 5px;
  display: none; }

.introPopup_transcript__kXkkh {
  cursor: pointer;
  display: flex; }

.introPopup_video-container__3Lv9M {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: black;
  padding: 20px 0;
  margin: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden; }

.introPopup_video___4qIv {
  background-color: black; }

.introPopup_icon__3MGG7 {
  height: 8px;
  width: 12px;
  padding-top: 10px; }

.introPopup_playing-conditions-container__qF9W2 {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  top: 0;
  left: 0; }

.introPopup_sprint-plan-header-container__1vXKJ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2vw;
  margin-right: 7vw; }

.introPopup_sprint-story-container__3emuy {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 2vw;
  margin-right: 3vw; }

.introPopup_sprint-stories-container__kwiA7 {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-gap: 7vh;
  gap: 7vh;
  overflow-y: scroll;
  margin-top: 0.5vh;
  padding-left: 0.13vw;
  padding-right: 0.2vw; }

.introPopup_sprint-plan-sprint-story-container__2OMu_ {
  width: -webkit-fill-available;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1.2vw;
  margin-top: 2vh;
  scroll-behavior: 'smooth';
  overflow-x: hidden;
  padding-right: 0.2vw; }

.introPopup_header__3UFKP {
  box-sizing: border-box;
  padding: 2vh 3vw;
  width: 91vw;
  display: flex;
  flex-direction: column;
  align-items: center; }

.introPopup_header-title__e_8wx {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; }

.introPopup_header-desc__2G9Vj {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5vh; }

.introPopup_sprint-number__38_HM {
  color: #fff;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2vh;
  font-size: 0.875em;
  margin-top: 1.223vh;
  margin-left: 0.5vw;
  padding: 0.5vh 0.5vw; }

.introPopup_sprint-story__2F5AF {
  height: 10vh;
  margin-bottom: 1vh; }

.introPopup_card-container__24ea0 {
  width: 6vw;
    width-height: 6vh; }

.introPopup_sprint-button-container__2XQdN {
  width: 90vw;
  margin-left: 5vw;
  margin-right: 3vw;
  margin-top: -3vh; }

.introPopup_card-container__24ea0 {
  width: 15.596vw;
  height: 16.667vh;
  box-sizing: border-box;
  margin: 0 7px 10px 5px;
  position: relative;
  margin-right: 1vw; }

.introPopup_card-tag__18VDD {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 0.5vw;
  align-items: center;
  padding: 0vh 0.3vw 0.1vh 0.1vw; }

.introPopup_card-tag-image__1nDBw {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.introPopup_card-tag-text__3n196 {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px; }

.introPopup_card-header__1oHF9 {
  line-height: 2.446vh;
  width: 14.297vw;
  font-weight: 600;
  font-size: 12px; }

.introPopup_card-content__3YhU_ {
  width: 14.373vw;
  height: 7.645vh;
  margin-top: 3.211vh;
  margin-left: 0.612vw; }

.introPopup_card-description__2qIBU {
  display: flex;
  width: 14.297vw;
  line-height: 2.141vh;
  margin-top: 3px;
  padding-top: 4px;
  font-weight: 350;
  font-size: 10px; }

.introPopup_card-bottom-container__U1mCO {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 3px;
  width: 95%;
  margin-left: 4px;
  justify-content: space-between; }

.introPopup_story-point-container__3FUff {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.introPopup_story-point-text__1nuWE {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.introPopup_story-point-number__3JCOB {
  font-weight: 600;
  font-size: 10px; }

.introPopup_story-point-icon__2u0o3 {
  display: flex;
  width: 8px;
  height: 8px; }

.introPopup_priority-container__1D1DA {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0px 0.293vw;
  border-radius: 2px; }

.introPopup_priority-text__2Lc6n {
  display: flex;
  font-weight: 400;
  font-size: 10px; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.sprintWisePlan_intro-content-container__2jB3f {
  padding-top: 25px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box; }

.sprintWisePlan_sprint-plan-header-container__3GSQM {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.sprintWisePlan_sprint-story-container__3DPzV {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 88.287vw;
  margin: auto; }

.sprintWisePlan_sprint-stories-container__1_wM1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-gap: 7vh;
  gap: 7vh;
  overflow-y: scroll;
  margin-top: 0.5vh;
  padding-left: 0.13vw;
  padding-right: 0.2vw; }

.sprintWisePlan_sprint-plan-sprint-story-container__1_8q2 {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2vh;
  scroll-behavior: 'smooth';
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding-right: 0.2vw;
  grid-gap: 1vh;
  gap: 1vh; }

.sprintWisePlan_header__3h3Xw {
  box-sizing: border-box;
  padding: 2vh 1.5vw;
  width: 88.287vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.sprintWisePlan_header-title__DutZx {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%; }

.sprintWisePlan_header-desc__ITUkh {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5vh; }

.sprintWisePlan_sprint-number__3z2Eh {
  color: #fff;
  height: 3.125vh;
  width: 4.8vw;
  line-height: 3.058vh;
  font-size: 14px;
  margin-top: 1.223vh;
  margin-left: 0.5vw;
  padding: 2px 0px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWisePlan_sprint-story__2f5Bg {
  height: 10vh;
  margin-bottom: 1vh; }

.sprintWisePlan_card-container__2QjGd {
  width: 6vw;
    width-height: 6vh; }

.sprintWisePlan_sprint-button-container__3bdwo {
  width: 88vw;
  margin: auto;
  margin-top: -3vh; }

.sprintWisePlan_card-container__2QjGd {
  width: 15.596vw;
  height: 16.667vh;
  box-sizing: border-box;
  margin: 0 7px 10px 5px;
  position: relative;
  margin-right: 0.2vw; }

.sprintWisePlan_card-tag__1aSzx {
  display: flex;
  position: absolute;
  grid-gap: 0.1vw;
  gap: 0.1vw;
  justify-content: space-evenly;
  z-index: 2;
  top: 0px;
  left: 0.5vw;
  align-items: center;
  padding: 0vh 0.3vw 0.1vh 0.1vw;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.sprintWisePlan_card-tag-image__3kfbp {
  width: 1.171vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.sprintWisePlan_card-tag-text__3RE_J {
  display: flex;
  font-weight: 600;
  font-size: 0.75em;
  align-items: center; }

.sprintWisePlan_card-header__3N5iv {
  line-height: 2.446vh;
  width: 14.297vw;
  font-weight: 600;
  font-size: 0.75em; }

.sprintWisePlan_card-content__3mLTR {
  width: 14.373vw;
  height: 7.645vh;
  margin-top: 3.211vh;
  margin-left: 0.4vw; }

.sprintWisePlan_card-description__2gq7y {
  display: flex;
  width: 14.297vw;
  line-height: 2.141vh;
  margin-top: 3px;
  padding-top: 4px;
  font-weight: 500;
  font-size: 0.625em; }

.sprintWisePlan_card-bottom-container__3lfqd {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 3px;
  width: 95%;
  margin-left: 4px;
  justify-content: space-between; }

.sprintWisePlan_story-point-container__2_CEn {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-left: 4px; }

.sprintWisePlan_story-point-text__1mLqk {
  line-height: 2.141vh;
  font-weight: 500;
  font-size: 0.625em; }

.sprintWisePlan_story-point-number__3t-jv {
  font-weight: 600;
  font-size: 0.625em; }

.sprintWisePlan_story-point-icon__3pX_S {
  display: flex; }

.sprintWisePlan_priority-container__1-M7Q {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.171vw;
  padding: 0px 0.293vw 0 0.293vw;
  border-radius: 2px; }

.sprintWisePlan_priority-text__2lk25 {
  display: flex;
  font-weight: 400;
  font-size: 0.625em; }

.sprintWisePlan_priority-image-container__oVsCD {
  width: 1.171vw;
  display: flex;
  align-items: center; }

.sprintWisePlan_priority-image__1ZOWq {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.history_b-msg__hSllP {
  font-size: 0.875em;
  color: #333333;
  font-weight: 500; }

.history_container__2Zkcu {
  height: 89.3vh;
  width: 53vw;
  box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #FBF1F4 0.01%, rgba(255, 255, 255, 0.94) 100%);
  margin-top: 8vh; }

.history_header__BFPRA {
  height: 6.771vh;
  width: 100%;
  background: linear-gradient(90deg, #972082 0%, #4B1C40 100%);
  display: flex;
  justify-content: space-between;
  align-items: center; }

.history_header-content__aRgi1 {
  font-size: 1.75em;
  padding-top: 1vh;
  font-weight: 600;
  color: white;
  padding-left: 2vw; }

.history_list__zY5J4 {
  margin-left: 5.857vw;
  width: 44vw;
  z-index: 1; }

.history_day__30NPW {
  position: relative;
  min-height: 13.021vh;
  margin-left: 1vw;
  border-left: 2px solid #989898; }

.history_number__1Lumz {
  position: absolute;
  left: -5.124vw;
  font-size: 0.875em;
  font-weight: 600;
  color: #333333; }

.history_box__1VtQS {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 13vh);
  box-sizing: border-box;
  opacity: 0.95;
  z-index: 0; }

.history_footer-svg__2wDaT {
  display: flex;
  position: fix;
  margin-top: -10vh;
  z-index: 0; }

.history_close__eA07D {
  position: absolute;
    position-width: 1.83vw;
    position-height: 3.255vh;
  top: 9.7vh;
  right: 5vw; }

.history_line__2896Z {
  width: 13.021vw; }

.history_circle__3u3ml {
  background: #FFFFFF;
  border: 1px solid #972082;
  box-sizing: border-box;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  position: absolute;
  left: -9px; }

.history_action__1jZJp {
  width: 48.829vw;
  border-top: 2px solid #fbbd0e;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_event__1GarK {
  width: 48.829vw;
  border-top: 2px solid #972082;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  margin-left: 15px;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker__I9yJa {
  width: 12.372vw;
  border-top: 2px solid #d11021;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
  padding: 10px;
  margin-bottom: 15px; }

.history_blocker-msg__1A8hi {
  width: 29.209vw;
  border-top: 2px solid #d11021;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 15px;
  padding: 1.5vh 1vw 1.5vh 1vw;
  margin-bottom: 15px; }

.history_header-tag__1M5JN {
  display: flex;
  align-items: center; }

.history_spacer__2vFPm {
  padding: 2px 0px;
  display: flex; }

.history_a-hash__3NwbC {
  font-size: 0.75em;
  color: #fbbd0e;
  font-weight: bold; }

.history_sprint-day-tag__1Juwh {
  padding: 2px 7px;
  background: rgba(2, 26, 32, 0.07);
  color: rgba(2, 26, 32, 0.7);
  font-size: 0.75em;
  margin-left: 10px;
  border-radius: 8px; }

.history_e-hash__1P1a9 {
  font-size: 0.75em;
  color: #972082;
  font-weight: bold; }

.history_b-hash__fKGTn {
  font-size: 0.75em;
  color: #d11021;
  font-weight: bold; }

.history_a-name__2_H5g {
  font-weight: 600;
  font-size: 0.875em;
  color: black; }

.history_action-option-name__142l6 {
  font-weight: 600;
  font-size: 0.875em;
  color: #333333;
  margin-top: 2px; }

.history_action-option__EN3o7 {
  height: 2.604vh;
  font-weight: 350;
  line-height: 2.604vh;
  font-size: 0.875em;
  margin-top: 2px;
  color: #333333; }

.history_e-msg__1QOId {
  font-size: 0.875em;
  color: #333333;
  margin-top: 1.042vh;
  font-weight: 500; }

.history_met-box__2-i4h {
  display: flex;
  margin-top: 1.302vh; }

.history_met-box-eve__1IIxy {
  margin-top: 1.953vh; }

.history_metric__2vVya {
  height: 2.804vh;
  border-radius: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  line-height: 2.083vh;
  margin: 1vh;
  display: flex;
  align-items: center; }

.history_arrow-cont__3LC86 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh; }

.history_metric-diff__1hcwy {
  display: flex;
  font-size: 0.75em;
  font-weight: 700;
  justify-content: center; }

.history_met-value__2Go7l {
  display: flex;
  font-size: 1em;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  width: 1.977vw;
  margin-left: 0.2vw; }

.history_met-container__ezbZe {
  margin-right: 0.732vw;
  display: flex;
  height: 5.208vh;
  border: 1px solid #989898;
  border-radius: 4px; }

.history_met-icon__3nyoG {
  display: flex;
  width: 1.757vw;
  margin-left: 0.22vw;
  margin-right: 0.146vw; }

.history_met__14kNU {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  margin-left: 0.293vw;
  margin-right: 0.19vw; }

.history_met-name__nmFnp {
  font-size: 0.625em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  color: black; }

.history_arrow__1UKPq {
  width: 1.171vw; }

.history_filter__3Cukx {
  height: 6vh;
  display: flex;
  margin-left: 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: center; }
  .history_filter__3Cukx div:first-child {
    font-size: 1em;
    color: #031A20;
    display: flex;
    justify-content: center; }

.history_filter-by__39Pw8 {
  margin-right: 0.586vw;
  display: flex;
  justify-content: center; }

.history_line-separator__2LcuO {
  width: 96.4%;
  height: 1px;
  background-color: #989898;
  opacity: 1;
  margin-left: auto; }

.history_filter-box__3xJdi {
  display: block;
  cursor: pointer;
  border: 1px solid #ee7748;
  padding: 4px 10px;
  border-radius: 4px;
  margin-left: 0.4vw;
  box-sizing: border-box;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center; }

.history_filter-name__13ja3 {
  margin-right: 0px; }

.history_slected__3ON4W {
  background-color: #ee7748; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

.areaMapPopup_leaderboard-popup-container__3-ErZ {
  width: 67vw;
  height: 77vh;
  box-sizing: border-box; }

.areaMapPopup_header-container__2gYMo {
  width: 100%;
  height: 6.5vh;
  z-index: 2;
  position: relative; }

.areaMapPopup_header-background-container__CtOdN {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.areaMapPopup_title__3b9R2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 1.2vw;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  text-size: 30px; }

.areaMapPopup_close-icon__3lm-2 {
  position: absolute;
  top: 1.2vh;
  right: 1vw;
  z-index: 1; }

.areaMapPopup_refresh-container__2Vx0r {
  position: absolute;
  top: 0.7vh;
  right: 8vw;
  z-index: 1; }

.areaMapPopup_table-header-container__3yP_Y {
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 10px; }

.areaMapPopup_position__2jx3i {
  min-width: 100px;
  box-sizing: border-box; }

.areaMapPopup_username__32Wif {
  min-width: 520px;
  box-sizing: border-box; }

.areaMapPopup_days-remaining__RrqhQ {
  min-width: 145px;
  box-sizing: border-box; }

.areaMapPopup_mvp__sm0le {
  min-width: 75px;
  box-sizing: border-box; }

.areaMapPopup_position-header__207Q3 {
  padding-left: 22px; }

.areaMapPopup_position-body__35lkP {
  display: flex;
  align-items: center;
  justify-content: center; }

.areaMapPopup_mvp-body__oO0Wy {
  text-align: right; }

.areaMapPopup_table-body-container__1ZXbl {
  height: calc(100% - 25px);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden; }

.areaMapPopup_table-row___plp6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0; }

.areaMapPopup_seperation-line__3anwQ {
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 0 16px;
  height: 1px; }

.areaMapPopup_table-container__3u2rH {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

.areaMapPopup_map-container__2C7OZ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 97%; }

.areaMapPopup_map-image__2UZFI {
  height: 95%;
  width: 97%;
  overflow: auto; }

.areaMapPopup_area-name__xqFdf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  grid-gap: 0.5vw;
  gap: 0.5vw; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf) format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Bold.50145685.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Semibold.33f225b8.ttf) format("truetype"), url(/static/media/OpenSans-Semibold.449d681c.woff) format("woff"), url(/static/media/OpenSans-Semibold.ddacaf4a.eot);
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf) format("truetype"), url(/static/media/OpenSans-Light.a7622f60.woff) format("woff"), url(/static/media/OpenSans-Light.e348323f.eot);
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/LibreBaskerville-Regular.27b9efe7.ttf) format("truetype"), url(/static/media/OpenSans-Regular.de0869e3.woff) format("woff"), url(/static/media/OpenSans-Regular.5f4d4bc1.eot); }

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/LibreBaskerville-Bold.e6d1a273.ttf) format("truetype"), url(/static/media/OpenSans-Bold.1f85e92d.woff) format("woff"), url(/static/media/OpenSans-Bold.fc0dbb2e.eot); }

@font-face {
  font-family: 'Digital';
  src: url(/static/media/digital-7.1e670d88.ttf) format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'freight-sans-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-display-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

@font-face {
  font-family: 'freight-text-pro';
  src: url("https://use.typekit.net/unk8txf.css"); }

