@import '~styles/mixins.sass';

.customers-container {
	@include display-flex-center-mixin;
	margin-left: 10px;
	width: 9.862vw;
	height: 70%;
	cursor: pointer; }

.customers-content {
	@include display-flex-center-mixin;
	flex-direction: column;
	width: 18px;
	height: 18px;
	box-sizing: border-box; }

.customers-name {
	padding: 0 0 0 5px;
	width: calc(100% - 36px);
	box-sizing: border-box; }

.customers-image {
	width: 14px; }
