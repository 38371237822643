@import '~styles/mixins.sass';

.metrics-container {
	width: 100%;
	margin-top: 3vh; }

.top-metrics-container {
	@include display-flex-start;
	// padding-right: 45px
 }	// margin-right: 18px

.left-metric-container {
	min-height: 67px;
	border-radius: 18px;
	margin: 0 1vh;
	box-sizing: border-box; }

.left-metrics-value-change-container {
	@include display-flex-start;
	margin-top: 5px; }

.metrics-image {
	width: 1.171vw;
	height: 2.083vh;
	margin-right: 8px; }

.bottom-metrics-container {
	display: flex;
	justify-content: flex-start;
	margin-top: 12px;
	margin-left: 15.4vw; }

.bottom-metrics-wrapper {
	@include display-flex-start;
	border-radius: 18px;
	box-sizing: border-box;
 }	// margin-left: 8px

.right-metric-container {
	// margin: 0 12px
	padding: 0 1vh; }

.metric-title {
	text-transform: uppercase; }

.right-metrics-value-change-container {
	@include display-flex-start; }

.metrics-value {
	margin-right: 0.878vw; }

.metrics-change {
	@include display-flex-center-mixin;
	min-width: 3.294vw;
	min-height: 2.604vh;
	padding-right: 0.5vw;
	border-radius: 16px;
	box-sizing: border-box;
	text-align: center; }

.arrow-container {
	width: 1.171vw;
	height: 2.083vh; }

.mvp-title-value-container {
	@include display-flex-start; }

.mvp-value {
	margin-left: 0.878vw; }

.sim-mvp-progress-bar-container {
	width: 100%;
	height: 6px;
	border-radius: 2px;
	@include display-flex-start; }

.sim-mvp-progress-bar {
	height: 6px;
	border-radius: 2px;
	display: flex; }

.game-metric-container {
	// min-height: 67px
	padding: 0 2.196vw;
	box-sizing: border-box; }

.game-metrics-container {
	@include display-flex-center-mixin; }

@import '~styles/mixins.sass';

.metric-container {
	@include display-flex-center-mixin;
	height: 5.208vh;
	margin-right: 1.464vw;
	padding-right: 0.3vw; }

.metric-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 0.4vw;
	height: 6.1vh;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 4px 6px; }

.metric-name-desc-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.metric-name-value-container {
	display: flex;
	flex-direction: column;
	align-itmes: start;
	justify-content: space-between;
	padding-top: 8px; }


.metric-name {
	padding-right: 1px; }

.metric-image-container {
	display: flex;
	height: 100%;
	align-items: center; }

.metric-image {
	width: 1.757vw; }

.metric-value {
	padding-left: 0px;
	margin-top: -0.6vh; }

.metric-value-right {
	margin-right: 3px; }
