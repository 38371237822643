.step-1-container {
    width: auto;
    height: 3.26vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 0 !important
}

.step-6-container {
    width: auto;
    height: 3.26vh;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 0 !important
}

.step-5-container {
    width: auto;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-5-container-text-box {
    text-align: initial;
    width: 100%;
}

.step-5-container-header {
    font-weight: 600;
    width: 100%;
    font-size: 1em;
    line-height: 2.8vh;
    color: black;
}

.step-5-container-text {
    font-size: 1em;
    font-weight: 500;
    width: 28vw;
    line-height: 2.683vh;
    color: #141414;
    text-align: left;
    padding-bottom: 0px !important
}

.step-1-container-text {
    font-weight: 500;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    width: 25vw;
    text-align: left;
}

.step-container-next {
    margin-top: 1.30vh;
    margin-left: 0.8vw;
    height: 3.125vh;
    padding-bottom: 0 !important;
}

.step-container-back {
    margin-top: 1.30vh;
    margin-left: 2.92vw;
    height: 4.17vh;
}

.step-1-container-arrow {
    margin-left: 0.4vw;
    width: 1.767vw;
    padding-bottom: 0 !important;
}

.step-arrow {
    display: flex;
    margin-left: 1vw;
    height: 4.17vh;
    padding-bottom: 0 !important
}

.step-button {
    display: flex;
    position: absolute;
    justify-content: center;
    top: 45vh;
    left: 33%;
    font-size: 1.171vw;
}

.step-button button {
    background-color: rgba(238, 119, 72, 1);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 1.30vh 2.92vw;
    font-size: 1.17vh;
    cursor: pointer;
}

.step-1-container-text-box {
    text-align: initial;
    margin-top: 10px;
    padding-bottom: 0 !important
}

.step-1-container-header {
    font-weight: 600;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    /* opacity: 0.8 */
}

.img-text {
    padding: 0 !important;
    font-size: 0.75em;
}

.step-4-container {
    display: flex;
}

.step-2-blocker-container {
    display: flex;
    height: 16.229vh;
    width: 12.275vw;
}

.step-2-container-text-box{
    padding-right: 10px;
    width: 29.283vw;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-blocker-container-text{
    font-weight: 500;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    text-align: left;
}

.step-2-container-text {
    font-weight: 500;
    width: 35.432vw;
    font-size: 1em;
    line-height: 2.683vh;
    color: black;
    text-align: left;
}

.step-2-container-text-action {
    font-size: 1em;
    width: 22vw;
    color: #141414;
    line-height: 2.683vh;
}

.step-2-container-text-action-list {
    font-size: 1em;
    color: black;
    line-height: 2.683vh;
}

.step-2-container {
    width: 37.775vw;
    height: auto;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-container {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.step-back {
    display: flex;
    position: absolute;
    right: 15px;
    width: 1.767vw;
    bottom: 0px;
}

.step-container-next-action-list {
   position: absolute;
   right: 1vw;
   bottom: 1vh;
   width: 1.767vw;
}

.step-container-event {
    position: absolute;
    right: 0vw;
    bottom: -3vh;
    width: 1.767vw;
 }

.step-1-container-action-list {
    width: 33.775vw;
    font-weight: 500;
    height: 15vh;
    font-size: 1em;
}

.step-1-container-action {
    width: 33.775vw;
    font-weight: 500;
    height: 12vh;
    font-size: 1em;
}

.step-1-container-arrow-report {
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-arrow-report-velocity {
    padding-bottom: 0 !important;
    width: 1.767vw;
    padding-right: 0.3vw;
}

.step-1-container-arrow-report-back {
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-single-action {
    padding-right: 1vw;
    font-weight: 500;
    font-size: 1em;
    width: 25.624vw;
}

.step-1-container-action-response {
    padding-right: 1vw;
    font-weight: 500;
    font-size: 1em;
}

.step-1-container-arrow-report-mvp-back{
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-1-container-arrow-report-mvp{
    padding-bottom: 0 !important;
    width: 1.767vw;
}

.step-2-container-text-box-action-list{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-2-container-text-box-action-response{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important;
    width: 64.495vw;
}

.step-2-container-text-box-blocker-response{
    padding-right: 10px;
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important;
    width: 28.624vw;
}

.step-2-container-text-box-retro{
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.step-2-container-text-box-report{
    margin-left: 10px;
    text-align: initial;
    padding-bottom: 0 !important
}

.tool-tip {
    position: absolute;
    bottom: -22.642vh;
    background-color: white;
    left: 0px;
    padding: 20px 15px 25px 15px;
    border-radius: 5px;
    max-width: 100%;
    box-sizing: border-box;
}

.tooltip-blocker {
    position: absolute;
    bottom: 25vh;
    background-color: white;
    left: calc(72.5vw + 72px);
    padding: 10px 0.5vw 10px 0.5vw;
    border-radius: 5px;
    max-width: 15.154vw;
    height: 11.328vh;
    box-sizing: border-box;
    padding-bottom: 10px !important;
}

.arrow {
    display: inline-flex; 
    position: absolute; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    top: 28.5vh;
    left: -19px;
}

.arrow-blocker {
    display: inline-flex; 
    position: absolute; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    bottom: 29vh;
    left: calc(72.5vw + 53px);
}

.spotlight {
    border-radius: 4px; 
    position: absolute; 
    background-color: rgba(128, 128, 128);
    width: 21.4vw; 
    height: 20.9vh; 
    left: calc((100vw - 88.287vw) / 2); 
    pointer-events: auto; 
    top: 61.2vh; 
    transition: opacity 0.2s ease 0s;
    z-index: 1000;
}

.step-1-container-velocity {
    width: 35.762vw;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-1-container-unfinshed {
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.step-1-container-efficiency {
    width: 34.81vw;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-1-container-event{
    width: 28.624vw;
    height: 8.549vh;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    position: relative
}

.step-2-container-event{
    width: 36vw;
    height: 14.5vh;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    position: relative;
    font-weight: 500;
}



.step-1-container-mvp {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-2-container-text-report{
    display: flex;
    align-items: center;
    gap: 0.5vw;
    font-size: 1em;
    font-weight: 500;
    line-height: 2.683vh;
    color: black;
}

.step-1-container-final {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.step-1-container-final1 {
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
}

.text-box-final-sprint {
    padding: 0 !important;
    text-align: initial;
}
