@import '~styles/mixins.sass';

.sprint-container {
	@include display-flex-center-mixin;
	flex-direction: column;
	width: 14.422vw;
	height: 50%; }

.sprint-content {
	@include display-flex-center-mixin;
	flex-direction: row;
	align-items: center;
	height: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0.6vh 0.2vw 0.6vh 0.4vw; }

.sprint-name-desc-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sprint-name-day-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	width: 3.9vw;
	max-height: 3.385vh; }

.sprint-name {
	padding-right: 1px;
	font-size: 0.625em; }

.sprint-day-progress-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 3.385vh; }

.sprint-day-container {
	width: 100%;
	height: 100%;
	font-size: 1em;
	margin-top: -5px; }

.sprint-progress-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	margin-left: 0.3vw;
	@include display-flex-center-mixin; }

.sprint-day {
	width: 0.35vw;
	height: 100%;
	margin-right: 0.366vw;
	border-radius: 5px; }

.sprint-current-day {
	width: 0.35vw;
	height: 100%;
	margin-right: 0.366vw;
	border-radius: 6px;
	@include display-flex-center-mixin; }
