@import '~styles/mixins.sass';

.tooltip-container-bottom {
    width: auto;
    position: absolute;
    top: 0.4vh;
    right: 14.1vw;
    z-index: 51;
    display: flex;
    justify-content: center;
    align-items: center; }

.tooltip-container-bottom {
	height: 3.646vh;
	white-space: nowrap; }


.tooltip-arrow-top-bottom {
	width: 0;
	height: 0;
	margin-top: 8px; }

.tooltip-arrow-right {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -0.7vw;
	width: 0.7vw; }

.tooltip-content {
    box-sizing: border-box;
    width: 100%;
    text-align: left; }

.tooltip-content {
	position: relative;
	padding: 0.7vh 0.5vw; }

.info-icon {
    width: 100%;
    height: 100%; }

.user-profile-container-with-options {
	@include display-flex-center-mixin;
	position: relative;
	outline: none; }

.user-profile-container {
	@include display-flex-center-mixin;
	cursor: pointer; }

.user-profile-image-container {
	width: 2vw;
	height: 2vh; }

.user-profile-arrow-container {
	margin-left: 0.4vw;
	width: 0.777vw;
	height: 2.446vh; }

.user-profile-option-container {
	position: absolute;
	width: 13.836vw;
	height: 4.2vw;
	z-index: 50;
	top: 5.3vh;
	left: -11vw;
	padding-top: 0.3vh; }

.each-option {
	width: 100%;
	padding: 1vh 0.5vw;
	box-sizing: border-box; }

.user-name-container {
	height: 4vh;
	width: 100%;
	box-sizing: border-box;
	padding: 0.5vh 0.5vw; }

.user-name-container {
	position: relative;
	overflow: visible; }

.user-name {
	height: 50%;
	width: 100%;
	text-align: left;
	padding: 5px 0;
	word-break: break-all; }
