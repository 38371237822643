.story {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    width: 17.3vw;
    height: 14.9vh;
    padding: 1vh 0.4vw;
    box-sizing: border-box;
    position: relative;
    border: 2px solid #DADADA;
    box-shadow: 0px 4px 4px 0px #0000001A; }

.stories {
    width: 100%;
    padding: 0vh 0.7vw 0.3vh 0.7vw;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1vh;
    overflow: auto;
    height: 15.2vh;
    margin-top: 0.5vh; }

.card-name {
    font-weight: 600;
    font-size: 0.75em;
    padding: 0px 0px 5px 0px;
    margin-top: 2vh; }

.card-description {
    font-weight: 600;
    font-size: 0.75em;
    padding: 0px 0px 10px 0px; }

.info {
    font-size: 12px;
    color: rgba(3,26,32,0.5);
    margin-top: 3px; }

.bottom {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0vh;
    width: 95%; }

.priority {
    border: 1px solid #42BAFF;
    box-sizing: border-box;
    border-radius: 20px;
    width: 33px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }

.stories-heading {
    box-sizing: border-box;
    padding: 1vh 0 0 0.7vw; }

.priority_arabic {
    border: 1px solid #42BAFF;
    box-sizing: border-box;
    border-radius: 20px;
    width: 60px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }

.priority-image {
    width: 10px;
    height: 10px;
    margin-right: 3px; }

.priority-letter {
    color: #42BAFF;
    font-weight: bold;
    font-size: 10px; }

.wrap {
    width: 100%;
    background-color: #474747B2;
    height: 19.922vh;
    margin-top: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1vw;
    border-radius: 4px; }

.wrap-popup {
    width: 100%;
    height: 19.922vh;
    margin-top: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1vw;
    border-radius: 4px;
    border: 1px solid #989898;
    padding-right: 0.3vw;
    box-sizing: border-box; }

.customers-speak {
    width: 70%; }

.priority-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.171vw;
    padding: 0px 0.293vw 0px 0.293vw;
    margin-bottom: 5px;
    box-sizing: border-box;
    border-radius: 2px; }

.priority-text {
    display: flex; }

.card-tag {
    padding: 1px 0.3vw 0.3vh 0.1vw;
    gap: 0.2vw; }

.card-tag {
	display: flex;
	position: absolute;
	z-index: 2;
	top: 0px;
	left: 0.45vw;
	border-bottom-right-radius: 2px; }

.card-tag {
    border-bottom-left-radius: 2px;
    align-items: center; }


.card-tag-image {
	width: 1.171vw;
	display: flex;
	align-items: center;
	justify-content: center; }

.card-tag-text {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1em; }


.priority-image-container {
	width: 1.171vw;
	display: flex;
	align-items: center; }
