@import '~styles/mixins.sass';

.card-container {
	cursor: pointer;
	// width: 21.9vw
	height: 20.642vh;
	box-sizing: border-box;
	padding: 1.432vh 0;
	margin: 0.1vw 0vw 1.302vh 0vw;
	position: relative; }

.card-child {
	width: 96%;
	height: 91%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%); }

.card-width {
	width: 21.154vw; }

.card-cursor {
	cursor: pointer; }

.card-popup {
	cursor: default; }

.card-header {
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 0.366vw 0 0.366vw; }

.card-heading-content {
	width: 85%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: hidden; }

.card-status-box {
	width: 1.757vw;
	height: 3.125vh;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	top: 1.042vh;
	right: 0.586vw; }

.desc-priority-container {
	overflow: hidden; }

.progress-container {
	width: 100%;
	height: 1.042vh;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	padding: 0 0.366vw 0 0.366vw; }

.progress-bar-background {
	width: 100%;
	height: 0.391vh;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 2px; }

.progress-bar {
	max-width: 100%;
	height: 0.391vh;
	border-radius: 4px; }

.story-point-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	margin-left: 4px; }

.story-point-text {
	line-height: 2.141vh;
	font-weight: 500;
	font-size: 0.625em; }

.story-point-number {
	font-weight: 600;
	font-size: 0.625em; }

.story-point-container-like-customer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// height: 15px
	padding: 0 0.878vw 0 0.878vw;
	box-sizing: border-box; }

.story-point {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.story-point-icon {
	display: flex;
	align-items: center; }

.like-customer-img {
	width: 24px;
	height: 3.125vh; }

.storypoints-text {
	padding-right: 10px; }

.feature-details-container {
	padding: 0 12px;
	box-sizing: border-box; }

.feature-container {
	padding: 2px 6px 5px 6px;
	box-sizing: border-box; }

.feature-image {
	width: 15px;
	height: 15px;
	display: inline-block;
	vertical-align: middle;
	padding-top: 2px; }

.feature-name {
	padding-left: 5px;
	box-sizing: border-box; }

.feature-desc {
	padding-left: 6px;
	box-sizing: border-box; }

.add-task-button-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }

.customer-like-icon {
	width: 1.171vw;
	height: 2.083vh;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }

.add-task-button {
	width: 1.757vw;
	height: 3.125vh;
	border-radius: 50%; }

.priorities-button-container {
	position: absolute;
	right: 0vw;
	z-index: 10;
	height: 100%;
	display: flex;
	align-items: center; }

.set-priority-heading {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.priority-buttons {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.each-priority {
	border-radius: 16px;
	box-sizing: border-box;
	height: 2.083vh;
	width: 2.489vw;
	margin-left: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center; }

.task-priority-container {
	position: absolute;
	right: -0.5vw;
	width: 3.7vw;
	height: 2.083vh;
	box-sizing: border-box;
	border-radius: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 3px; }

.task-priority-image {
	width: 0.732vw;
	height: 1.302vh; }

.task-priority-letter {
	padding-left: 3px; }

.blocker-tag-component {
	width: 97%;
	height: 2.344vh;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin: auto; }

.blocker-image {
	width: 0.878vw;
	height: 1.563vh; }

.blocker-text {
	height: 100%;
	padding-left: 0.512vw;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.card-tag {
	display: flex;
	position: absolute;
	gap: 0.1vw;
	justify-content: space-evenly;
	top: 0px;
	left: 0.366vw;
	padding: 0vh 0.3vw 0.1vh 0.1vw;
	align-items: center; }

.card-tag-image {
	width: 1.171vw;
	display: flex;
	justify-content: center;
	align-items: center; }


.card-tag-text {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 0.75em; }

.card-content {
	margin-top: 2vh; }

.card-description {
	display: flex;
	line-height: 2.141vh;
	padding-top: 4px;
	padding-right: 0.586vw;
	font-weight: 350;
	font-size: 0.75em;
	margin-left: 0.366vw; }

.card-bottom-container {
	display: flex;
	flex-direction: row;
	//align-items: center
	position: absolute;
	bottom: 3px;
	width: 95%;
	height: 2.083vh;
	margin-left: 4px;
	margin-bottom: 0.651vh;
	justify-content: space-between; }

.priority-container-down-right {
	position: absolute;
	right: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 1.171vw;
	padding: 0px 0.293vw 0 0.293vw;
	border-radius: 2px;
 }	//margin-bottom: 8px

.priority-text {
	display: flex;
	font-weight: 400; }

.priority-container-up-right {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 1.171vw;
	padding: 0px 0.293vw 0 0.293vw;
	border-radius: 2px;
 }	//margin-bottom: 8px


.priority-container-down-left {
	position: absolute;
	right: 4vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 1.171vw;
	padding: 0px 0.293vw 0 0.293vw;
	//margin-bottom: 8px
	padding-right: 6px;
	box-sizing: border-box;
	border-radius: 2px; }

.priority-image-container {
	width: 1.171vw;
	display: flex;
	align-items: center; }

