.tab-container {
    display: flex;
    height: 5.208vh;
    border-bottom: 2px solid #DADADA;
    width: calc(100% - 11.468vw); }

.tab-container-popup {
    display: flex;
    height: 5.208vh;
    border-bottom: 2px solid #989898;
    width: 82%; }

.tab {
    height: 100%;
    width: 6.149vw;
    text-align: center;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    color: #FFFFFF; }

.selected {
    color: #EE7748;
    font-weight: 600;
    font-size: 1em;
    border-bottom: 2px solid #EE7748; }

.popup {
    color: black; }

.number {
    padding-top: 1.3vh; }
