@import '~styles/mixins.sass';

.sprint-report-popup-container {
    width: 96vw;
    height: 73.047vh;
    border-radius: 8px;
    overflow: hidden;
    position: relative; }

@media only screen and (max-height: 700px) {
    .sprint-report-popup-container {
 } }       // height: 550px

.sprint-report-popup-header {
    width: 100%;
    height: 73.047vh; }
