@import '~styles/mixins.sass';

.event-actions-container {
    width: 88.76vw;
    height: 40.234vh;
    border-radius: 8px;
    margin-top: 50vh;
    position: relative; }

.event-child {
    width: 87.2vw;
    height: 37.5vh;
    position: absolute;
    top: 50.5%;
    left: 50%;
    transform: translate(-50%,-50%); }

.event-actions-header {
    width: 100%;
    height: 6.25vh;
    padding-left: 1vw;
    display: flex;
    align-items: center;
    box-sizing: border-box; }

.event-text {
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 3vh;
	width: 44.656vw; }

.event-actions-content {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 2.2vh 3vw 1vh 3vw;
    box-sizing: border-box;
    position: relative; }

.event-actions-content {
	height: 33.985vh;
	gap: 2vw; }

.event-image-container {
    width: 20.328vw; }

.image {
	width: 100%; }

.event-action-list {
    height: 100%; }

.metrics-container {
    display: flex; }

.metric-container {
    height: 5.208vh;
    margin-right: 1.464vw;
    padding-right: 0.3vw;
    display: flex; }

.metric-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4vw;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 4px 6px; }

.metric-image {
	width: 1.757vw; }

.metric-image-container {
	display: flex;
	height: 100%;
	align-items: center; }

.metric-name-value-container {
	display: flex;
	flex-direction: column;
	align-itmes: start;
	justify-content: space-between;
	padding-top: 8px; }

.metric-name-desc-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.metric-name {
	padding-right: 1px; }

.metric-value {
	padding-left: 0px;
	margin-top: -0.6vh; }

.metrics-change {
	@include display-flex-center-mixin;
	min-width: 3.294vw;
	min-height: 2.604vh;
	padding-right: 0.5vw;
	border-radius: 16px;
	box-sizing: border-box;
	text-align: center; }

.arrow-container {
	width: 1.171vw;
	height: 2.083vh; }

.close-container {
	position: absolute;
	bottom: 4vh;
	right: 2.5vw; }
