@import '~styles/mixins.sass';

.option-container {
	display: flex;
	width: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0.5vh 0.5vw;
	margin-bottom: 1vh;
	cursor: pointer; }

.radio-container {
	width: 0.878vw;
	display: flex;
	align-items: flex-end;
	margin-bottom: 2.9vh;
	padding-right: 0.5vw; }


.option-details-container {
	width: 100%; }

.radio-outline {
	@include display-flex-center-mixin;
	border-radius: 50%; }

.radio-inner-circle {
	width: 0.439vw;
	height: 0.439vw;
	border-radius: 50%;
	margin: 0.18vw;
	box-sizing: border-box; }

.option-effort-cost-container {
	@include display-flex-space-between;
 }	// margin-top: 5px

.option-effort-cost-content {
	@include display-flex-start; }

.option-effort-cost-title {
	// padding-right: 5px
	margin-right: 5px; }

