
.wrap {
    overflow: auto;
    width: 100%;
    background-color: #474747B2;
    height: 46.745vh;
    margin-top: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1vw;
    border-radius: 4px;
    padding: 1vh 0.5vw 1vh 0.5vw;
    box-sizing: border-box; }

.wrap-popup {
    overflow: auto;
    width: 100%;
    height: 46.745vh;
    margin-top: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1vw;
    border-radius: 4px;
    border: 1px solid #989898;
    padding: 1vh 0.5vw 1vh 0.5vw;
    box-sizing: border-box; }

.retro-content {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    overflow-y: auto;
    overflow-x: hidden; }

.single-retro-point {
    display: flex;
    gap: 0.8vw; }

.retro-message {
    width: 32vw; }

.met-name {
	display: flex;
	height: 4.427vh;
	padding: 4px 16px 4px 1vw;
	border-radius: 4px;
	box-sizing: border-box; }

.met-name {
    align-items: center;
    width: 35.2vw; }

.image-container {
    width: 1.769vw; }
