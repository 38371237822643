@import '~styles/mixins.sass';

.tm-container {
	@include display-flex-center-mixin;
	width: 8.126vw;
	height: 50%;
	cursor: pointer; }

.tm-content {
	@include display-flex-center-mixin;
	flex-direction: column;
	width: 1.757vw;
	height: 3.125vh;
	box-sizing: border-box; }

.tm-name {
	padding: 0 0 0 0.5vh;
	width: calc(100% - 3.4vw);
	box-sizing: border-box; }

.tm-image {
	width: 1.757vw;
	height: 3.125vh; }
